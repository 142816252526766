import React, { useEffect, useState } from 'react';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend);

const Development = () => {
    const [period, setPeriod] = useState('30');
    const [selectedMember, setSelectedMember] = useState('');
    const [members, setMembers] = useState([]);
    const [chartData, setChartData] = useState({ datasets: [] });
    const [uploadCounts, setUploadCounts] = useState({ currentCount: 0, previousCount: 0 });
    const [medianData, setMedianData] = useState({ currentMedian: 0, previousMedian: 0 });
    const [percentChange, setPercentChange] = useState({ change: 0 });

    useEffect(() => {
        fetch(process.env.REACT_APP_SITE_URL + '/members')
            .then(response => response.json())
            .then(data => {
                setMembers(data);
            })
            .catch(error => console.error('Error fetching members:', error));
    }, []);

    useEffect(() => {
        const memberParam = selectedMember ? `&mid=${selectedMember}` : '';
        fetch(process.env.REACT_APP_SITE_URL + `/uploads/development-data?period=${period}${memberParam}`)
            .then(response => response.json())
            .then(data => {
                const processedData = processChartData(data, period);
                setChartData(processedData.chartData);
                setUploadCounts(processedData.uploadCounts);
                setMedianData(processedData.medianData);
                setPercentChange(processedData.percentChange);
            })
            .catch(error => console.error('Error fetching development data:', error));
    }, [period, selectedMember]);

    const processChartData = (data, period) => {
        const segments = 10;
        const now = moment();
        let currentPeriodDays;
        let previousPeriodDays;
        let startDate, previousPeriodStartDate, endDate, previousEndDate;

        switch (period) {
            case '30':
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '60':
                currentPeriodDays = 60;
                previousPeriodDays = 60;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '90':
                currentPeriodDays = 90;
                previousPeriodDays = 90;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '6m':
                currentPeriodDays = 6 * 30;
                previousPeriodDays = 6 * 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case '12m':
                currentPeriodDays = 12 * 30;
                previousPeriodDays = 12 * 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
                break;
            case 'short':
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            case 'mid':
                currentPeriodDays = 60;
                previousPeriodDays = 60;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            case 'long':
                currentPeriodDays = 90;
                previousPeriodDays = 90;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(previousPeriodDays, 'days');
                previousEndDate = startDate;
                break;
            default:
                currentPeriodDays = 30;
                previousPeriodDays = 30;
                startDate = now.clone().subtract(currentPeriodDays, 'days').startOf('day');
                previousPeriodStartDate = startDate.clone().subtract(365, 'days');
                previousEndDate = previousPeriodStartDate.clone().add(currentPeriodDays, 'days');
        }

        const currentPeriodData = Array(segments).fill(0);
        const previousPeriodData = Array(segments).fill(0);

        let currentCount = 0;
        let previousCount = 0;

        data.forEach(item => {
            const createdDate = moment(item.created);
            if (createdDate.isBetween(startDate, now)) {
                currentCount++;
                const segment = Math.floor((currentPeriodDays - now.diff(createdDate, 'days') - 1) / (currentPeriodDays / segments));
                currentPeriodData[segment]++;
            } else if (createdDate.isBetween(previousPeriodStartDate, previousEndDate)) {
                previousCount++;
                const segment = Math.floor((previousPeriodDays - previousEndDate.diff(createdDate, 'days') - 1) / (previousPeriodDays / segments));
                previousPeriodData[segment]++;
            }
        });

        const currentMedian = calculateMedian(currentPeriodData);
        const previousMedian = calculateMedian(previousPeriodData);

        const change = calculatePercentChange(currentMedian, previousMedian);

        return {
            chartData: {
                labels: Array.from({ length: segments }, (_, i) => {
                    const segmentStart = startDate.clone().add(i * (currentPeriodDays / segments), 'days');
                    const segmentEnd = segmentStart.clone().add(currentPeriodDays / segments - 1, 'days');
                    return `${segmentStart.format('MM/DD')} - ${segmentEnd.format('MM/DD')}`;
                }),
                datasets: [
                    {
                        label: 'Current Period',
                        data: currentPeriodData,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        yAxisID: 'y',
                    },
                    {
                        label: 'Previous Period',
                        data: previousPeriodData,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        yAxisID: 'y1',
                    }
                ]
            },
            uploadCounts: {
                currentCount,
                previousCount
            },
            medianData: {
                currentMedian,
                previousMedian
            },
            percentChange: {
                change
            }
        };
    };

    const calculateMedian = (data) => {
        const sortedData = data.slice().sort((a, b) => a - b);
        const mid = Math.floor(sortedData.length / 2);

        return sortedData.length % 2 !== 0 ? sortedData[mid] : (sortedData[mid - 1] + sortedData[mid]) / 2;
    };

    const calculatePercentChange = (current, previous) => {
        if (previous === 0) return current > 0 ? 100 : 0;
        return ((current - previous) / previous * 100).toFixed(2);
    };

    const handlePeriodChange = (event) => {
        setPeriod(event.target.value);
    };

    const handleMemberChange = (event) => {
        setSelectedMember(event.target.value);
    };

    const containerStyle = {
        padding: '20px',
        backgroundColor: '#121212',  // Dark background
        color: '#ffffff',  // White text
        borderRadius: '8px',
        maxWidth: '1200px',
        margin: '0 auto',
        marginTop: '40px',
    };

    const selectStyle = {
        marginBottom: '20px',
        padding: '10px',
        borderRadius: '4px',
        backgroundColor: '#333333',
        color: '#ffffff',
        border: 'none',
        marginRight: '10px',
    };

    const chartContainerStyle = {
        height: '500px',
    };

    const medianContainerStyle = {
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: '20px',
    };

    const medianBoxStyle = {
        padding: '10px',
        backgroundColor: '#333333',
        color: '#ffffff',
        borderRadius: '4px',
        textAlign: 'center',
    };

    const percentChangeStyle = {
        marginTop: '10px',
        fontSize: '14px',
    };

    const medianChartData = {
        labels: ['Current Period', 'Previous Period'],
        datasets: [
            {
                label: 'Median Uploads',
                data: [medianData.currentMedian, medianData.previousMedian],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)'
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)'
                ],
                borderWidth: 1,
            }
        ]
    };

    return (
        <div style={containerStyle}>

            <div>
                <select style={selectStyle} value={period} onChange={handlePeriodChange}>
                    <option value="30">Last 30 days (vs last year)</option>
                    <option value="60">Last 60 days (vs last year)</option>
                    <option value="90">Last 90 days (vs last year)</option>
                    <option value="6m">Last 6 months (vs last year)</option>
                    <option value="12m">Last 12 months (vs last year)</option>
                    <option value="short">Shorttime dev (30vs30-60)</option>
                    <option value="mid">Midtime dev (60vs60-120)</option>
                    <option value="long">Longtime dev (90vs90-180)</option>
                </select>
                <select style={selectStyle} value={selectedMember} onChange={handleMemberChange}>
                    <option value=''>All Members</option>
                    {members.map(member => (
                        <option key={member.id} value={member.id}>{member.name}</option>
                    ))}
                </select>
            </div>
            <div style={medianContainerStyle}>
                <div style={medianBoxStyle}>
                    <h3>Current Period Uploads</h3>
                    <p>{uploadCounts.currentCount}</p>
                    <p style={percentChangeStyle}>Change from Previous Period: {percentChange.change}%</p>
                </div>
                <div style={medianBoxStyle}>
                    <h3>Previous Period Uploads</h3>
                    <p>{uploadCounts.previousCount}</p>
                </div>
            </div>
            <div style={chartContainerStyle}>
                <Line data={chartData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                color: '#ffffff'  // White text for the legend
                            }
                        },
                        title: {
                            display: true,
                            text: 'Uploads Comparison Over Time',
                            color: '#ffffff'  // White text for the title
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#ffffff'  // White text for x-axis
                            }
                        },
                        y: {
                            type: 'linear',
                            display: true,
                            position: 'left',
                            ticks: {
                                color: '#ffffff'  // White text for y-axis
                            },
                            title: {
                                display: true,
                                text: 'Uploads',
                                color: '#ffffff'  // White text for y-axis title
                            }
                        },
                        y1: {
                            type: 'linear',
                            display: true,
                            position: 'right',
                            ticks: {
                                color: '#ffffff'  // White text for y-axis
                            },
                            title: {
                                display: true,
                                text: 'Uploads',
                                color: '#ffffff'  // White text for y-axis title
                            },
                            grid: {
                                drawOnChartArea: false,  // only want the grid lines for one axis to show up
                            }
                        }
                    }
                }} />
            </div>
            <div style={chartContainerStyle}>
                <Bar data={medianChartData} options={{
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                color: '#ffffff'  // White text for the legend
                            }
                        },
                        title: {
                            display: true,
                            text: 'Median Uploads Comparison',
                            color: '#ffffff'  // White text for the title
                        }
                    },
                    scales: {
                        x: {
                            ticks: {
                                color: '#ffffff'  // White text for x-axis
                            }
                        },
                        y: {
                            ticks: {
                                color: '#ffffff'  // White text for y-axis
                            },
                            title: {
                                display: true,
                                text: 'Median Uploads',
                                color: '#ffffff'  // White text for y-axis title
                            }
                        }
                    }
                }} />
            </div>
        </div>
    );
};

export default Development;

import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import alert from '../assets/alert.gif';
import { UserContext } from '../context/UserContext';
import useFCM from './useFCM';
export default function FilePortal() {
  const [showChat, setShowChat] = useState(false);
  const toggleChat = () => {
    setShowChat(!showChat);
  };
  const [vehicleData, setVehicleData] = useState([]);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [newsArticles, setNewsArticles] = useState([]);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(0);
  const [showDropdown, setShowDropdown] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const { authToken } = useAuth();
  const { userProfile, setUserProfile } = useContext(UserContext);
  const { fcmToken } = useFCM(authToken, userProfile.id);
  const [fcmStatus, setFcmStatus] = useState('Initializing...');
const [isAutoRefreshOn, setIsAutoRefreshOn] = useState(false); // State for autorefresh


    useEffect(() => {
      const fetchRefreshStatus = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/get-refresh-status/${userProfile.id}`);
          const data = await response.json();
          if (response.ok) {
            setIsAutoRefreshOn(data.refresh === 1);
          } else {
            console.error('Failed to fetch refresh status:', data.message);
          }
        } catch (error) {
          console.error('Error fetching refresh status:', error);
        }
      };

      if (userProfile.id) {
        fetchRefreshStatus();
      }
    }, [userProfile.id]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isTuner, setIsTuner] = useState(false);
  const { logout } = useAuth();
  const [isSupport, setIsSupport] = useState(false);
  const toggleDropdown = (index) => {
    setShowDropdown(prevState => {
      // Hvis dropdown allerede er åpen for den valgte indeksen, lukk den
      if (prevState[index]) {
        return {};
      }
      // Hvis en annen dropdown er åpen, lukk den og åpne dropdown for den valgte indeksen
      return { [index]: true };
    });
  };

  const handleRowClick = fileId => {
    setSelectedFileId(fileId);
    setShowModal(true);
  };

  const [selectedDealer, setSelectedDealer] = useState('');
  const [wipOnly, setWipOnly] = useState(false);


  useEffect(() => {
    fetchUserStats();
  }, []);

  useEffect(() => {
    const fetchVehicleData = async () => {
      let url = process.env.REACT_APP_SITE_URL + `/uploads?page=${page}&limit=500&searchText=${encodeURIComponent(searchText)}`;
      if (selectedDealer) {
        url += `&dealerId=${selectedDealer}`;
      }
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        }
      });
      const newData = await response.json();
      setVehicleData(prev => page === 1 ? newData : [...prev, ...newData]);
    };
    fetchVehicleData();
  }, [page, searchText, selectedDealer]);

  useEffect(() => {
    const fetchNews = async () => {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/news');
      const newsData = await response.json();
      setNewsArticles(newsData);
    };
    fetchNews();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentNewsIndex(prevIndex => (prevIndex + 1) % newsArticles.length);
    }, 10000);

    return () => clearInterval(interval);
  }, [newsArticles.length]);

  const getTuningTypeLabel = (tuningTypeId) => {
    const tuningTypes = {
      '1': { label: 'E', className: 'eco' },
      '3': { label: 'O', className: 'opti' },
      '4': { label: 'P', className: 'power' },
      '5': { label: 'S', className: 'stock' },
      '6': { label: 'X', className: 'extreme' }
    };

    return tuningTypes[tuningTypeId] || { label: 'Ukjent', className: '' };
  };

  const formatDateTime = (dateTimeString) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
    return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateTimeString));
  };

  const sortedVehicleData = [...vehicleData].sort((a, b) => {
    const alertA = (isAdmin && a.tunercheck === 1) || (!isAdmin && a.dealercheck === 1);
    const alertB = (isAdmin && b.tunercheck === 1) || (!isAdmin && b.dealercheck === 1);

    if (a.status !== 2 && b.status === 2) return -1;
    if (a.status === 2 && b.status !== 2) return 1;
    if (alertA && !alertB) return -1;
    if (!alertA && alertB) return 1;
    return b.id - a.id;
  });


  const [userStats, setUserStats] = useState({
    todayFiles: 0,
    weeklyFiles: 0,
    monthlyFiles: 0,
    yearlyFiles: 0
  });


const fetchUserStats = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/stats`);
    const data = await response.json();
    console.log('Fetched stats:', data);  // Debugging
    setUserStats(data);
  } catch (error) {
    console.error('Error fetching total stats:', error);
  }
};


const nuh = new Date(Date.now()).toLocaleTimeString('no-NO');

  return (
    <div className="container">
      <div className="samlecontainer">
      <div className="news-column">
          <div className="box-container">
          <div className="box">Today<br></br>{userStats.todayFiles || 0}</div>
          <div className="box">Weekly<br></br>{userStats.weeklyFiles || 0}</div>
          <div className="box">Monthly<br></br>{userStats.monthlyFiles || 0}</div>
          <div className="box">Yearly<br></br>{userStats.yearlyFiles || 0}</div>
          </div>
      </div>
      </div>
      <div className="files-section">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Vehicle</th>
              <th>Registration</th>
              <th>Dealer</th>
              <th></th>
              <th></th>
              <th>Tuner</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {sortedVehicleData.map((vehicle, index) => {
              const showAlert = (isAdmin && vehicle.message === 1 && vehicle.tunercheck === 1) || (!isAdmin && vehicle.message === 2 && vehicle.dealercheck === 1);
              return (
                <tr key={index} className={`status-${vehicle.status}`} onClick={() => handleRowClick(vehicle.id)}>
                  <td>{vehicle.id}</td>
                  <td>{vehicle.vehicleDescription || 'Ukjent Kjøretøy'}</td>
                  <td>{vehicle.regnum}</td>
                  <td>{vehicle.dealerName || 'Ukjent Dealer'}</td>
                  <td>{showAlert ? (<img src={alert} alt="Alert" className="alert" />) : null}</td>
                  <td>
                    {vehicle.status === 0 && (
                      <>
                        <span className={`tuningType ${getTuningTypeLabel(vehicle.tuning_type).className}`}>
                          {getTuningTypeLabel(vehicle.tuning_type).label}
                        </span>
                        {vehicle.egr !== 0 && <p className="file-option-small egr">E</p>}
                        {vehicle.dpf !== 0 && <p className="file-option-small dpf">D</p>}
                        {vehicle.adblue !== 0 && <p className="file-option-small adblue">A</p>}
                        {vehicle.nsl !== 0 && <p className="file-option-small nsl">NSL</p>}
                        {vehicle.o2 !== 0 && <p className="file-option-small o2">O2</p>}
                        {vehicle.epa !== 0 && <p className="file-option-small epa">EPA</p>}
                        {vehicle.hybridturbo !== 0 && <p className="file-option hybrid-turbo">HT</p>}
                        {vehicle.gearbox !== 0 && <p className="file-option gearbox">GT</p>}
                        {vehicle.coldstart !== 0 && <p className="file-option cold-start">CS</p>}
                        {vehicle.startstop !== 0 && <p className="file-option start-stop">SS</p>}
                        {vehicle.popsbangs !== 0 && <p className="file-option pops-bangs">PB</p>}
                        {vehicle.burbles !== 0 && <p className="file-option burbles">BU</p>}
                        {vehicle.swirlflaps !== 0 && <p className="file-option swirl-flaps">SW</p>}
                        {vehicle.tva !== 0 && <p className="file-option tva">TVA</p>}
                      </>
                    )}
                  </td>
                  <td>{vehicle.tuner}</td>
                  <td>{formatDateTime(vehicle.created)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

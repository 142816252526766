import React from 'react';

const ProfileSection = React.memo(({
  userProfile,
  isAdmin,
  isTuner,
  showWarning,
  taskCountToday,
  pendingCount,
  isAutoRefreshOn,
  toggleAutoRefresh,
  openProfileModal,
  openAdminModal,
  openOohModal,
  openTosModal,
  handleLogout
}) => {
  return (
    <div className="profile-column">
      <span>{userProfile.name} - {userProfile.companyName}</span><br />
      <span>Your credits: {userProfile.companyCredits}</span><br />
      {(!isAdmin && !isTuner && showWarning) && (
        <span style={{ color: 'red' }}>
          Task scheduled after closing time! Request an OOH tuner.<br />
        </span>
      )}
      {(isAdmin || isTuner) && (
        <span>
          Expected files today: {taskCountToday}
          {pendingCount > 0 && (
            <span style={{ color: 'red' }}> - Pending ooh requests: {pendingCount}</span>
          )}
          <br />
        </span>
      )}
      <span>Company current rank: {userProfile.companyRank}</span><br />
      <span>Files to rank up for company: {userProfile.companyLevel}</span><br />
      <div className="button-container">
        <div className="left-buttons">
          <button className="profile-button" onClick={openProfileModal}>Dashboard</button>
          {(isAdmin || isTuner) && (
            <button className="admin-button" onClick={openAdminModal}>ADMIN</button>
          )}
          {(!isAdmin && !isTuner) && (
            <button className="ooh-button" onClick={openOohModal}>Request OOH tuner</button>
          )}
          <button className="tos-button" onClick={openTosModal}>Terms of Service</button>
          <div className="auto-refresh-slider">
            <span className="auto-refresh-text">Autorefresh</span>
            <label>
              <input
                type="checkbox"
                checked={isAutoRefreshOn}
                onChange={toggleAutoRefresh}
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        <button className="logout-button" onClick={handleLogout}>LOG OUT</button>
      </div>
    </div>
  );
});

export default ProfileSection;
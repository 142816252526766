import { useState, useEffect } from 'react';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { messaging } from '../firebase';

const useFCM = (authToken, userId) => {
  const [fcmToken, setFcmToken] = useState('');
  const [fcmStatus, setFcmStatus] = useState('Initializing...');
  const [notificationPermission, setNotificationPermission] = useState(Notification.permission);

  useEffect(() => {
    if (!authToken || !userId) {
      setFcmStatus('Waiting for authentication...');
      return;
    }

    const requestPermissionAndToken = async () => {
      try {
        let permission = Notification.permission;
        if (permission === 'default') {
          permission = await Notification.requestPermission();
        }
        setNotificationPermission(permission);

        if (permission === 'granted') {
          const currentToken = await getToken(messaging, { vapidKey: 'BNkZRhmweFQZsQe23JwcOnaCuVjHqoOJtYVFQIefBg0e4BNqq6f1xkPMgbSeqXgxnt4pSWbsPM1ZBZ1uqZ6aszE' });
          if (currentToken) {
            setFcmToken(currentToken);
            await sendTokenToServer(currentToken, userId, authToken);
            setFcmStatus('FCM Token registered successfully');
          } else {
            setFcmStatus('No registration token available');
          }
        } else {
          setFcmStatus('Notification permission denied');
        }
      } catch (error) {
        console.error('An error occurred while setting up notifications:', error);
        setFcmStatus('Error setting up notifications');
      }
    };

    requestPermissionAndToken();

    const unsubscribe = onMessage(messaging, (payload) => {
      showNotification(payload);
    });

    return () => unsubscribe();
  }, [authToken, userId]);

  const sendTokenToServer = async (token, userId, authToken) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/register-fcm-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({ token, userId }),
      });

      if (!response.ok) {
        throw new Error('Failed to register FCM token');
      }

    } catch (error) {
      console.error('Error registering FCM token:', error);
      setFcmStatus('Error registering FCM token');
    }
  };

  const showNotification = (payload) => {

    if (Notification.permission !== 'granted') {
      return;
    }

    let title = 'New Notification';
    let body = '';
    let icon = '/path/to/default-icon.png'; // Replace with your actual icon path

    if (payload.notification) {
      title = payload.notification.title || title;
      body = payload.notification.body || body;
      icon = payload.notification.icon || icon;
    } else if (payload.data) {
      title = payload.data.title || title;
      body = payload.data.body || body;
      icon = payload.data.icon || icon;
    }

    const notificationOptions = {
      body,
      icon,
      badge: '/path/to/badge-icon.png', // Add a badge icon
      tag: 'new-message', // Add a tag to group similar notifications
      renotify: true, // Cause a notification with the same tag to renotify
      requireInteraction: true, // The notification remains until the user interacts with it
      vibrate: [200, 100, 200], // Vibration pattern for mobile devices
      data: payload.data,
    };

    try {
      const notification = new Notification(title, notificationOptions);
      notification.onclick = function(event) {
        window.focus();
        notification.close();
        // Add any specific click handling here
      };

      // Force the notification to show
      setTimeout(() => {
        notification.close();
        new Notification(title, notificationOptions);
      }, 100);
    } catch (error) {
      console.error('Error creating notification:', error);
    }
  };

  return { fcmToken, fcmStatus, notificationPermission };
};

export default useFCM;

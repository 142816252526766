import React, { memo } from 'react';
import { SvgAT, SvgD, SvgT, SvgM } from './SvgIcons';
import alert from '../assets/alert.gif';
import of_car from '../assets/of_car.png';
import of_construction from '../assets/of_construction.png';
import of_marine from '../assets/of_marine.png';
import of_mc from '../assets/of_mc.png';
import of_tracktor from '../assets/of_tracktor.png';
import of_truck from '../assets/of_truck.png';

import autotuner from '../assets/autotuner.png';
import newgenius from '../assets/newgenius.png';
import trasdata from '../assets/trasdata.png';
import other from '../assets/other.png';



const getVehicleImage = (v_type) => {
  switch (v_type) {
    case 1:
      return <img src={of_car} alt="Car" className="vehicle-image" />;
    case 6:
      return <img src={of_truck} alt="Truck" className="vehicle-image" />;
    case 8:
      return <img src={of_tracktor} alt="Tractor" className="vehicle-image" />;
    case 9:
      return <img src={of_construction} alt="Construction" className="vehicle-image" />;
    case 10:
      return <img src={of_marine} alt="Marine" className="vehicle-image" />;
    case 14:
      return <img src={of_mc} alt="MC/ATV/Snowmobile" className="vehicle-image" />;
    default:
      return null; // Handle cases where v_type is not recognized
  }
};


const VehicleRow = memo(({
  vehicle,
  isAdmin,
  isTuner,
  handleRowClick,
  handleUpdateStatus,
  showDropdown,
  toggleDropdown,
  handleAction
}) => {
  const showAlert = (isAdmin && vehicle.message === 1 && vehicle.tunercheck === 1) || (!isAdmin && vehicle.message === 2 && vehicle.dealercheck === 1);

  const getTuningTypeLabel = (tuningTypeId) => {
    const tuningTypes = {
      '1': { label: 'E', className: 'eco' },
      '3': { label: 'O', className: 'opti' },
      '4': { label: 'P', className: 'power' },
      '5': { label: 'S', className: 'stock' },
      '6': { label: 'X', className: 'extreme' }
    };
    return tuningTypes[tuningTypeId] || { label: 'Ukjent', className: '' };
  };

  const formatDateTime = (dateTimeString) => {
    const options = {
      timeZone: 'UTC',
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateTimeString));
  };

  const getTooltypeImage = (tooltype) => {
    switch (tooltype) {
      case 1:
        return <img src={autotuner} alt="Autotuner" className="tooltype-image" />;
      case 3:
        return <img src={newgenius} alt="NewGenius" className="tooltype-image" />;
      case 10:
        return <img src={trasdata} alt="Trasdata" className="tooltype-image" />;
      case 9:
        return <img src={other} alt="Other" className="tooltype-image" />;
      default:
        return <img src={other} alt="Other" className="tooltype-image" />;
    }
  };
  

  return (
    <tr className={`status-${vehicle.status}`} onClick={() => handleRowClick(vehicle.id)}>
      <td>{vehicle.id}</td>
      <td>{vehicle.vehicleDescription || 'Ukjent Kjøretøy'}</td>
      <td>{vehicle.regnum}</td>
      <td>{vehicle.dealerName || 'Ukjent Dealer'}</td>
      <td>{showAlert && <img src={alert} alt="Alert" className="alert" />}</td>
      <td>
      <span className={`tuningType ${getTuningTypeLabel(vehicle.tuning_type).className}`}>{getTuningTypeLabel(vehicle.tuning_type).label}</span>
      {vehicle.egr !== 0 && <span className="file-option-small egr">E</span>}
      {vehicle.dpf !== 0 && <span className="file-option-small dpf">D</span>}
      {vehicle.adblue !== 0 && <span className="file-option-small adblue">A</span>}
      {vehicle.nsl !== 0 && <span className="file-option-small nsl">NSL</span>}
      {vehicle.o2 !== 0 && <span className="file-option-small o2">O2</span>}
      {vehicle.epa !== 0 && <span className="file-option-small epa">EPA</span>}
      {vehicle.hybridturbo !== 0 && <span className="file-option-small hybrid-turbo">HT</span>}
      {vehicle.gearbox !== 0 && <span className="file-option-small gearbox">GT</span>}
      {vehicle.coldstart !== 0 && <span className="file-option-small cold-start">CS</span>}
      {vehicle.startstop !== 0 && <span className="file-option-small start-stop">SS</span>}
      {vehicle.popsbangs !== 0 && <span className="file-option-small pops-bangs">PB</span>}
      {vehicle.burbles !== 0 && <span className="file-option-small burbles">BU</span>}
      {vehicle.swirlflaps !== 0 && <span className="file-option-small swirl-flaps">SW</span>}
      {vehicle.tva !== 0 && <span className="file-option-small tva">TVA</span>}
      </td>
            {!(isAdmin || isTuner) && (
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
          <span className={`status-box ${vehicle.paid === 1 ? 'paid' : 'not-paid'}`}>
            {vehicle.paid === 1 ? '✔' : '✖'}
          </span>
        </td>
      )}
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        {getVehicleImage(vehicle.v_type)}
      </td>
      {(isAdmin || isTuner) && (
                      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{vehicle.tuner}</td>
      )}
      <td>{formatDateTime(vehicle.created)}</td>
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>{vehicle.charged}</td>
      {(isAdmin || isTuner) && (
        <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
      {getTooltypeImage(vehicle.tooltype)}
    </td>

      )}
      <td style={{ textAlign: 'center', verticalAlign: 'middle' }}>
        <div className="action-buttons">
          {(parseInt(vehicle.status) !== 2 || (isAdmin && vehicle.tunercheck === 1) || (!isAdmin && vehicle.dealercheck === 1)) && (
            (() => {
              if ((!isAdmin && !isTuner) && parseInt(vehicle.status) !== 2) {
                return null;
              } else {
                return (
                  <button
                    className="action-button done-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleUpdateStatus(vehicle.id, 2, isAdmin);
                    }}
                  >
                    Done
                  </button>
                );
              }
            })()
          )}
          {(isAdmin || isTuner) && (
            <div className="action-button more-button">
              <button onClick={(e) => {
                e.stopPropagation();
                toggleDropdown();
              }}>...</button>
              {showDropdown && (
                <div className="dropdown-content" onClick={(e) => e.stopPropagation()}>
                  <p onClick={() => handleAction('Sent')}>Sent</p>
                  <p onClick={() => handleAction('In progress')}>In progress</p>
                  <p onClick={() => handleAction('Hold')}>Hold</p>
                  <p onClick={() => handleAction('Support')}>Support</p>
                  <p onClick={() => handleAction('Delete')}>Delete</p>
                  <p onClick={() => handleAction('Credback')}>Creditback</p>
                </div>
              )}
            </div>
          )}
        </div>
      </td>
    </tr>
  );
});

export default VehicleRow;
// // Newscenter.js
// import React from 'react';

// export default function Newscenter() {
//     return (
//         <div>
//             <h2>Newscenter</h2>
//             <p>This is the Newscenter content.</p>
//         </div>
//     );
// }




import React, { useState, useEffect, useRef } from 'react';

export default function Newscenter({ id }) {
    const [newsTitles, setNewsTitles] = useState([]);
    const [selectedNewsId, setSelectedNewsId] = useState(null);
    const [selectedNews, setSelectedNews] = useState(null);
    const [newNews, setNewNews] = useState({ title: '', body: '' });
    const [editingNews, setEditingNews] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);

    // Ref to handle auto-scrolling
    const newsItemRefs = useRef({});

    useEffect(() => {
        fetchNewsTitles();
    }, []);

    const fetchNewsTitles = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/news-titles');
            const data = await response.json();
            setNewsTitles(data);
        } catch (error) {
            console.error('Error fetching news titles:', error);
        }
    };

    const fetchNewsById = async (newsId) => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + `/news/${newsId}`);
            const data = await response.json();
            setSelectedNewsId(selectedNewsId === newsId ? null : newsId);  // Collapse if the same is clicked
            setSelectedNews(data);

            // Scroll into view after selecting the news item
            if (newsItemRefs.current[newsId]) {
                newsItemRefs.current[newsId].scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                });
            }
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

    const handleCreate = async () => {
        try {
            const formattedBody = newNews.body.replace(/\n/g, '<br>');
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/news', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newNews, body: formattedBody, creator: id })
            });

            if (response.ok) {
                const data = await response.json();
                setNewsTitles([...newsTitles, { id: data.id, title: data.title }]);
                setNewNews({ title: '', body: '' });
                setShowCreateForm(false);  // Hide create form after creating news
            } else {
                console.error('Failed to create news:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating news:', error);
        }
    };

    const handleUpdate = async (newsId, updatedNews) => {
        try {
            const formattedBody = updatedNews.body.replace(/\n/g, '<br>');
            const response = await fetch(process.env.REACT_APP_SITE_URL + `/news/${newsId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...updatedNews, body: formattedBody })
            });

            if (response.ok) {
                setNewsTitles(newsTitles.map(item => (item.id === newsId ? { id: newsId, title: updatedNews.title } : item)));
                setSelectedNews(updatedNews);
                setEditingNews(null);
            } else {
                console.error('Failed to update news:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating news:', error);
        }
    };

    const handleDelete = async (newsId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/news/${newsId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setNewsTitles(newsTitles.filter(item => item.id !== newsId));
                setSelectedNews(null);
            } else {
                console.error('Failed to delete news:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };

    const handleCreateToggle = () => {
        setShowCreateForm(!showCreateForm);
        setSelectedNews(null);
    };

    return (
        <div className="news-container">
            <h2>News</h2>

            

            <div className="news-list-container">
                {newsTitles.map((item) => (
                    <div 
                        key={item.id} 
                        className={`news-item ${selectedNewsId === item.id ? 'active' : ''}`} 
                        onClick={() => fetchNewsById(item.id)}
                        ref={(el) => newsItemRefs.current[item.id] = el}  
                    >
                        <div className="news-title2">
                            {item.title}
                        </div>
                        {/* Show news content below the title if this news is clicked */}
                        {selectedNewsId === item.id && selectedNews && (
                            <div className="news-content">
                                <div dangerouslySetInnerHTML={{ __html: selectedNews.body }} />
                                

                                
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

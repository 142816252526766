import React, { createContext, useState } from 'react';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userProfile, setUserProfile] = useState({
    id: '',
    name: '',
    legal: '',
    email: '',
    phone: '',
    vat: '',
    address: '',
    city: '',
    country: '',
    postal: '',
    admin: '',
    tuner: '',
    dealer: '',
    rank: '',
    level: '',
    registred_host: '',
    updated_ip: '',
    login_date: '',
    login_user_agent: '',
    opti: '',
    wl: '',
    approved: '',
    disabled: '',
    pid: '',
    did: '',
    companyName: '',
    companyRank: '',
    companyLevel: '',
    companyCredits: 0
  });

  return (
    <UserContext.Provider value={{ userProfile, setUserProfile }}>
      {children}
    </UserContext.Provider>
  );
};
import React, { useState, useEffect, useCallback } from 'react';

const WarningSection = React.memo(({ initialWarnings, isAdmin, userDid }) => {
  const [warnings, setWarnings] = useState(initialWarnings);
  const [editMode, setEditMode] = useState(null);
  const [newText, setNewText] = useState('');
  const [newStatus, setNewStatus] = useState('red');
  const [showWarning, setShowWarning] = useState(false);

  const colorMap = {
    red: '#a34242a8',
    yellow: '#918e00d3',
    green: '#507a509f'
  };

  const checkForWarning = useCallback(async () => {
    if (!isAdmin && userDid) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/tasks/check-warning/${userDid}`);
        const data = await response.json();
        setShowWarning(data.showWarning);
      } catch (error) {
        console.error('Error checking for warning:', error);
      }
    }
  }, [isAdmin, userDid]);

  const fetchWarnings = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/warnings`);
      const data = await response.json();
      setWarnings(data);
    } catch (error) {
      console.error('Error fetching warnings:', error);
    }
  }, []);

  useEffect(() => {
    checkForWarning();
    fetchWarnings();
  }, [checkForWarning, fetchWarnings]);

  const saveWarning = async (warningNumber) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/warnings`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ warning: warningNumber, text: newText, status: newStatus }),
      });
      await response.json();
      fetchWarnings();
      setEditMode(null);
    } catch (error) {
      console.error('Error saving warning:', error);
    }
  };

  const deleteWarning = async (warningNumber) => {
    try {
      await fetch(`${process.env.REACT_APP_SITE_URL}/warnings/${warningNumber}`, {
        method: 'DELETE',
      });
      fetchWarnings();
    } catch (error) {
      console.error('Error deleting warning:', error);
    }
  };

  const renderWarning = (warningNumber) => {
    const warning = warnings.find(w => w.warning === warningNumber);
    const backgroundColor = warning ? colorMap[warning.status] || warning.status : null;

    return (
      <div key={warningNumber} style={{ display: 'flex', alignItems: 'center' }}>
        {warning ? (
          <span style={{
            color: 'white',
            backgroundColor: backgroundColor,
            padding: '2px 5px',
            borderRadius: '2px',
            margin: '3px',
            fontWeight: 'bold',
          }}>
            {warning.text}
          </span>
        ) : (
          isAdmin && <span>No warning set</span>
        )}

        {isAdmin && (
          <div style={{ marginLeft: '30px' }}>
            {editMode === warningNumber ? (
              <div>
                <input
                  type="text"
                  value={newText}
                  onChange={e => setNewText(e.target.value)}
                  placeholder="Enter warning text"
                />
                <select
                  value={newStatus}
                  onChange={e => setNewStatus(e.target.value)}
                >
                  <option value="red">Red</option>
                  <option value="yellow">Yellow</option>
                  <option value="green">Green</option>
                </select>
                <button onClick={() => saveWarning(warningNumber)}>Save</button>
                <button onClick={() => setEditMode(null)}>Cancel</button>
              </div>
            ) : (
              <>
                <button onClick={() => {
                  setEditMode(warningNumber);
                  setNewText(warning?.text || '');
                  setNewStatus(warning?.status || 'red');
                }}>Edit</button>
                {warning && <button onClick={() => deleteWarning(warningNumber)}>Delete</button>}
              </>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="warning-section">
      {[1, 2, 3].map(renderWarning)}
      {!isAdmin && showWarning && (
        <span style={{ color: 'red' }}>
          Task scheduled after closing time! Request an OOH tuner.
        </span>
      )}
    </div>
  );
});

export default WarningSection;
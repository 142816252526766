import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import logo from '../assets/logo.png'; // Oppdater stien etter den faktiske plasseringen

function ResetPassword() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const { token } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords don't match");
      return;
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_SITE_URL}/reset-password`, { token, newPassword: password });
      setMessage(response.data.message);
    } catch (error) {
      setMessage(error.response.data.message);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <img src={logo} alt="Logo" className="logo-inlog" />
        <p className="detailtext"><b>New Password</b></p>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="New password"
          required
        />
        <p className="detailtext"><b>Confirm New Password</b></p>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          placeholder="Confirm new password"
          required
        />
        <button type="submit">Update Password</button>
        {message && <p className="message-text">{message}</p>}
      </form>
    </div>
  );
}

export default ResetPassword;
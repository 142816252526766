import React, { useState, useEffect } from 'react';

export default function Ooh({ onClose, id }) {
    const [requests, setRequests] = useState([]);
    const [filter, setFilter] = useState('All');
    const [overrideDates, setOverrideDates] = useState([]);
    const [newDate, setNewDate] = useState('');

    useEffect(() => {
    }, [id]);

    useEffect(() => {
        fetchOohRequests();
        fetchOverrideDates();
    }, []);

    const fetchOohRequests = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh`);
            const data = await response.json();
            setRequests(data);
        } catch (error) {
            console.error('Error fetching ooh requests:', error);
        }
    };

    const fetchOverrideDates = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`);
            const dates = await response.json();
            setOverrideDates(dates.map(date => date.split('T')[0])); // Format date to YYYY-MM-DD
        } catch (error) {
            console.error('Error fetching override dates:', error);
        }
    };

    const handleFilterChange = (status) => {
        setFilter(status);
    };

    const handleAcceptRequest = async (requestId) => {
        const payload = { status: 1, tuner: id };
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/ooh/${requestId}/accept`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });
            if (response.ok) {
                fetchOohRequests();
            } else {
                console.error('Error accepting ooh request');
            }
        } catch (error) {
            console.error('Error accepting ooh request:', error);
        }
    };

    const handleDateChange = (e) => {
        setNewDate(e.target.value);
    };

    const handleDateSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ date: newDate })
            });
            if (response.ok) {
                fetchOverrideDates();
                setNewDate('');
            } else {
                console.error('Error adding override date');
            }
        } catch (error) {
            console.error('Error adding override date:', error);
        }
    };
    const handleDateDelete = async (date) => {
        try {
            const encodedDate = encodeURIComponent(date);
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/override-dates/${encodedDate}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                fetchOverrideDates();
            } else {
                const errorText = await response.text();
                console.error('Error deleting override date:', errorText);
            }
        } catch (error) {
            console.error('Error deleting override date:', error);
        }
    };



    const filterRequests = () => {
        if (filter === 'All') {
            return requests;
        }
        return requests.filter(request => request.status === (filter === 'Requested' ? 0 : 1));
    };

    const getStatusLabel = (status) => {
        return status === 0 ? 'Requested' : 'Accepted';
    };

    const formatDateTime = (dateTimeString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(dateTimeString).toLocaleString('no-NO', { timeZone: 'UTC' }, options).replace(',', ' -');
    };

    return (
        <div className="ooh-background" onClick={onClose}>
            <div className="ooh-content" onClick={e => e.stopPropagation()}>
                <div className="ooh-header">
                    <h2>Out of Hour Requests</h2>
                </div>
                <div className="ooh-filters">
                    <button onClick={() => handleFilterChange('All')}>All</button>
                    <button onClick={() => handleFilterChange('Requested')}>Requested</button>
                    <button onClick={() => handleFilterChange('Accepted')}>Accepted</button>
                </div>
                <table className="ooh-table">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Department</th>
                            <th>Status</th>
                            <th>Tuner</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterRequests().map(request => (
                            <tr key={request.id}>
                                <td>{formatDateTime(request.date)}</td>
                                <td>{request.dealer_name}</td>
                                <td>{getStatusLabel(request.status)}</td>
                                <td>{request.tuner_name}</td>
                                <td>
                                    {request.status === 0 && (
                                        <button onClick={() => handleAcceptRequest(request.id)}>Accept</button>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="override-dates-section">
                    <h3>Closed Dates</h3>
                    <ul>
                        {overrideDates.map((date, index) => (
                            <li key={index}>
                                {date}
                                <button onClick={() => handleDateDelete(date)}>Delete</button>
                            </li>
                        ))}
                    </ul>
                    <form onSubmit={handleDateSubmit}>
                        <label htmlFor="newDate">Add Closed Date:</label>
                        <input
                            type="date"
                            id="newDate"
                            value={newDate}
                            onChange={handleDateChange}
                            required
                        />
                        <button type="submit">Add Date</button>
                    </form>
                </div>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

import React, { useEffect, useState } from 'react';

const Logsite = () => {
  const [logs, setLogs] = useState([]);
  const [displayedLogs, setDisplayedLogs] = useState([]);
  const [displayCount, setDisplayCount] = useState(500);

  useEffect(() => {
    fetchLogs();
  }, []);

  const fetchLogs = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/logsite/logs');
      const data = await response.json();
      // Sort the logs in descending order based on the 'created' date
      const sortedData = data.sort((a, b) => new Date(b.created) - new Date(a.created));
      setLogs(sortedData);
      setDisplayedLogs(sortedData.slice(0, displayCount));
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };



  const handleLoadMore = () => {
    setDisplayCount(displayCount + 500);
  };

  return (
    <div>
      <h3>Logs</h3>
      <div>


      </div>
      <table>
        <thead>
          <tr>
            <th>Log ID</th>
            <th>Log Text</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {displayedLogs.map(log => (
            <tr key={log.id}>
              <td>{log.id}</td>
              <td>{log.op_text}</td>
              <td>{new Date(log.created).toLocaleString('no-NO', { timeZone: 'UTC' })}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {displayedLogs.length < logs.length && (
        <div style={{ textAlign: 'center', margin: '20px 0' }}>
          <button onClick={handleLoadMore}>Load More</button>
        </div>
      )}
    </div>
  );
};

export default Logsite;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const CreditShop = ({ userId, onPurchaseComplete }) => {
    const [creditAmount, setCreditAmount] = useState(1);
    const [bonusCredits, setBonusCredits] = useState(0);
    const [price, setPrice] = useState(100);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        // Initialize Stripe
        setStripePromise(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY));

        // Calculate bonus credits and price
        const newBonusCredits = creditAmount >= 425 ? 50 : 0;
        setBonusCredits(newBonusCredits);
        setPrice(creditAmount * 100);
    }, [creditAmount]);

    const handlePurchase = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SITE_URL}/create-checkout-session`, {
                creditAmount,
                userId
            });
            const { id: sessionId } = response.data;

            // Redirect to Stripe Checkout
            const stripe = await stripePromise;
            if (stripe) {
                await stripe.redirectToCheckout({ sessionId });
            } else {
                console.error('Stripe has not been initialized');
            }
        } catch (error) {
            console.error('Error creating checkout session:', error);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <div className="credit-shop">
            <h2>Purchase Credits</h2>
            <div>
                <label>Add credits: </label>
                <input
                    type="number"
                    value={creditAmount}
                    onChange={(e) => setCreditAmount(Math.max(1, parseInt(e.target.value)))}
                    min="1"
                />
                <span> +{bonusCredits} bonus credits</span>
            </div>
            <p className="price-text">Price: {price} NOK</p>
            <button className="buy-button" onClick={handlePurchase}>Purchase Credits</button>
            <div className="description-container">
                <p>These credits can be used on OptiFiles for purchasing original files or tuning files.</p>
                <ul>
                    <li>Valid for any vehicles</li>
                    <li>All file types supported</li>
                    <li>Support for all tools</li>
                    <li>Technical Support & Assistance included</li>
                    <li>Valid for 1 year</li>
                    <li>Non-refundable</li>
                </ul>
            </div>
        </div>
    );
};

export default CreditShop;
import React, { useState, useEffect } from 'react';


function AddVeh({ onClose }) {
  const [modelName, setModelName] = useState('');
  const [typeId, setTypeId] = useState('');
  const [manufacturerId, setManufacturerId] = useState('');
  const [rangeId, setRangeId] = useState('');
  const [powerOpti, setPowerOpti] = useState('');
  const [torqueOpti, setTorqueOpti] = useState('');
  const [power, setPower] = useState('');
  const [torque, setTorque] = useState('');
  const [obdLocation, setObdLocation] = useState('');
  const [ecuLocation, setEcuLocation] = useState('');
  const [notes, setNotes] = useState('');
  const [fueltype, setFueltype] = useState('');
  const [rangeName, setRangeName] = useState('');
  const [manufacturerName, setManufacturerName] = useState('');
  const [ranges, setRanges] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [types, setTypes] = useState([]);
  const [activeForm, setActiveForm] = useState('vehicle');

  useEffect(() => {
    // Fetch vehicle types
    fetch(process.env.REACT_APP_SITE_URL + '/types')
      .then(response => response.json())
      .then(data => setTypes(data))
      .catch(error => console.error('Error fetching types:', error));
  }, []);

  useEffect(() => {
    if (typeId) {
      // Fetch manufacturers when a type is selected
      fetch(`${process.env.REACT_APP_SITE_URL}/manufacturers?typeId=${typeId}`)
        .then(response => response.json())
        .then(data => setManufacturers(data))
        .catch(error => console.error('Error fetching manufacturers:', error));
    }
  }, [typeId]);

  useEffect(() => {
    if (manufacturerId) {
      // Fetch ranges when a manufacturer is selected
      fetch(`${process.env.REACT_APP_SITE_URL}/ranges?manufacturerId=${manufacturerId}`)
        .then(response => response.json())
        .then(data => setRanges(data))
        .catch(error => console.error('Error fetching ranges:', error));
    }
  }, [manufacturerId]);

  // Function to round up to the nearest number ending with 0 or 5
  const roundUpToNearest5or0 = (num) => {
    return Math.ceil(num / 5) * 5;
  };

  // Automatically suggest power and torque based on powerOpti and torqueOpti
  useEffect(() => {
    if (powerOpti) {
      const suggestedPower = roundUpToNearest5or0(parseFloat(powerOpti) * 1.1);
      setPower(suggestedPower.toString());
    }
  }, [powerOpti]);

  useEffect(() => {
    if (torqueOpti) {
      const suggestedTorque = roundUpToNearest5or0(parseFloat(torqueOpti) * 1.1);
      setTorque(suggestedTorque.toString());
    }
  }, [torqueOpti]);

  const handleSubmitVehicle = (e) => {
    e.preventDefault();
    const vehicleData = {
      modelName,
      rangeId,
      manufacturerId,
      typeId,
      power,
      torque,
      obdLocation,
      ecuLocation,
      notes,
      fueltype,
      power_opti: powerOpti,
      torque_opti: torqueOpti
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addVehicleModel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(vehicleData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Vehicle model added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding vehicle model:', error);
    });
  };

  const handleSubmitRange = (e) => {
    e.preventDefault();
    const rangeData = {
      typeId,
      manufacturerId,
      rangeName,
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addRange', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(rangeData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Range added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding range:', error);
    });
  };

  const handleSubmitManufacturer = (e) => {
    e.preventDefault();
    const manufacturerData = {
      typeId,
      manufacturerName,
    };

    fetch(process.env.REACT_APP_SITE_URL + '/addManufacturer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(manufacturerData)
    })
    .then(response => response.json())
    .then(data => {
      alert('Manufacturer added successfully');
      onClose();
    })
    .catch(error => {
      console.error('Error adding manufacturer:', error);
    });
  };

  return (
    <div className="addveh-modal-background" onClick={onClose}>
      <div className="addveh-modal-content" onClick={e => e.stopPropagation()}>
        <div className="form-container">
          <form className="vehicle-form" onSubmit={handleSubmitVehicle}>
            <h2>Add Vehicle Model</h2>
            <label>
              Vehicle Type:
              <select value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                <option value="">Select Type</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))}
              </select>
            </label>
            {typeId && (
              <>
                <label>
                  Manufacturer:
                  <select value={manufacturerId} onChange={(e) => setManufacturerId(e.target.value)} required>
                    <option value="">Select Manufacturer</option>
                    {manufacturers.map(manufacturer => (
                      <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.value}</option>
                    ))}
                  </select>
                </label>
              </>
            )}
            {manufacturerId && (
              <>
                <label>
                  Range:
                  <select value={rangeId} onChange={(e) => setRangeId(e.target.value)} required>
                    <option value="">Select Range</option>
                    {ranges.map(range => (
                      <option key={range.id} value={range.id}>{range.value}</option>
                    ))}
                  </select>
                </label>
              </>
            )}
            {rangeId && (
              <>
                <label>
                  Model Name:
                  <input
                    type="text"
                    value={modelName}
                    onChange={(e) => setModelName(e.target.value)}
                    required
                  />
                </label>
                <label>
                  Fuel Type:
                  <select value={fueltype} onChange={(e) => setFueltype(e.target.value)} required>
                    <option value="">Select Fuel Type</option>
                    <option value="2">Diesel</option>
                    <option value="3">Petrol</option>
                    <option value="5">Hybrid</option>
                  </select>
                </label>
                <label>
                  Opti (HP):
                  <input
                    type="text"
                    value={powerOpti}
                    onChange={(e) => setPowerOpti(e.target.value)}
                    required
                  />
                </label>
                <label>
                  Opti (NM):
                  <input
                    type="text"
                    value={torqueOpti}
                    onChange={(e) => setTorqueOpti(e.target.value)}
                    required
                  />
                </label>
                <label>
                  Power (HP):
                  <input
                    type="text"
                    value={power}
                    onChange={(e) => setPower(e.target.value)}
                    required
                  />
                </label>
                <label>
                  Power (NM):
                  <input
                    type="text"
                    value={torque}
                    onChange={(e) => setTorque(e.target.value)}
                    required
                  />
                </label>
                <label>
                  OBD Location:
                  <input
                    type="text"
                    value={obdLocation}
                    onChange={(e) => setObdLocation(e.target.value)}
                  />
                </label>
                <label>
                  ECU Location:
                  <input
                    type="text"
                    value={ecuLocation}
                    onChange={(e) => setEcuLocation(e.target.value)}
                  />
                </label>
                <label>
                  Notes:
                  <textarea
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </label>
                <button type="submit">Add Vehicle Model</button>
              </>
            )}
          </form>
          <form className="range-form" onSubmit={handleSubmitRange}>
            <h2>Add Range</h2>
            <label>
              Vehicle Type:
              <select value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                <option value="">Select Type</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))}
              </select>
            </label>
            {typeId && (
              <>
                <label>
                  Manufacturer:
                  <select value={manufacturerId} onChange={(e) => setManufacturerId(e.target.value)} required>
                    <option value="">Select Manufacturer</option>
                    {manufacturers.map(manufacturer => (
                      <option key={manufacturer.id} value={manufacturer.id}>{manufacturer.value}</option>
                    ))}
                  </select>
                </label>
              </>
            )}
            <label>
              Range Name:
              <input
                type="text"
                value={rangeName}
                onChange={(e) => setRangeName(e.target.value)}
                required
              />
            </label>
            <button type="submit">Add Range</button>
          </form>
          <form className="manufacturer-form" onSubmit={handleSubmitManufacturer}>
            <h2>Add Manufacturer</h2>
            <label>
              Vehicle Type:
              <select value={typeId} onChange={(e) => setTypeId(e.target.value)} required>
                <option value="">Select Type</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>{type.value}</option>
                ))}
              </select>
            </label>
            <label>
              Manufacturer Name:
              <input
                type="text"
                value={manufacturerName}
                onChange={(e) => setManufacturerName(e.target.value)}
                required
              />
            </label>
            <button type="submit">Add Manufacturer</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddVeh;

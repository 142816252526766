import React, { useState } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { useNavigate } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';  // Import ForgotPassword component
import logo from '../assets/logo.png'; // Oppdater stien etter den faktiske plasseringen

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showModal, setShowModal] = useState(false); // State for showing the modal
    const { login } = useAuth();
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/login', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email, password })
            });
            const data = await response.json();
            if (response.ok) {
                login(data.token);
                navigate('/');  // Navigate to the homepage or desired component route
            } else {
                alert(data.message); // Show error message from server
            }
        } catch (error) {
            console.error('Login failed:', error);
            alert('Login failed, check console for details.');
        }
    };

    const handleForgotPassword = () => {
        setShowModal(true); // Show the modal when the button is clicked
    };

    const closeModal = () => {
        setShowModal(false); // Close the modal
    };

    return (
        <div className="login-container">
            <form onSubmit={handleSubmit} className="login-form">
            <img src={logo} alt="Logo" className="logo-inlog" />
            <p className="detailtext"><b>Username</b> </p>
                <input
                    type="email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                />
            <p className="detailtext"><b>Password</b> </p>
                <input
                    type="password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                />
                <button type="submit">Login</button>
                <button type="button" onClick={handleForgotPassword} className="forgot-password">
                    Forgot Password
                </button>
            </form>
            {showModal && (
                <div className="modal-forgot">
                    <div className="modal-forgot-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <ForgotPassword />
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // Eller hvilken som helst global CSS-fil du har
import App from './App'; // Pass på at stien er riktig

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

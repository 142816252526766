import React from 'react';
import FileDetails from './FileDetails'; // Importer FileDetails hvis du vil vise modal med denne komponenten

const FileDetailsMatch = ({
  fileDetails,
  regnumMatches,
  isAdmin,
  isTuner,
  handleBoxClick,
  showModal,
  selectedFileId,
  closeModal,
  userProfile
}) => {
  return (
    <>
      {fileDetails && (isAdmin || isTuner) && (
        <div className="info-box">
          <p className="detailtext"><b>{fileDetails.regnum} matches id:</b></p>
          {regnumMatches.length > 0 ? (
            <div className="id-boxes-container">
              {regnumMatches.map(id => (
                <div key={id} className="id-box" onClick={() => handleBoxClick(id)}>
                  ID: {id}
                </div>
              ))}
            </div>
          ) : (
            <p></p>
          )}
          <br />
        </div>
      )}

      {showModal && (
        <FileDetails
          fileId={selectedFileId}
          onClose={closeModal}
          isAdmin={isAdmin}
          isTuner={isTuner}
          userProfile={userProfile}
        />
      )}
    </>
  );
};

export default FileDetailsMatch;

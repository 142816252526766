import React, { useMemo } from 'react';

const ScrollFeed = React.memo(({ latestUploads }) => {
  const scrollText = useMemo(() => {
    return latestUploads.map(vehicle =>
      `${vehicle.dealerName || 'Unknown Dealer'} - ${vehicle.vehicleDescription || 'Unknown Vehicle'} - `
    ).join('  / /  ');
  }, [latestUploads]);

  return (
    <div className="scroll-feed-container">
      <div className="scroll-feed">
        <p className="scroll-text-class">
          {scrollText}
        </p>
      </div>
    </div>
  );
});

export default ScrollFeed;
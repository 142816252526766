import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token'));

    const refreshToken = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/refresh-token`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json'
                }
            });
            if (response.ok) {
                const data = await response.json();
                login(data.token);
                return data.token;
            } else {
                throw new Error('Failed to refresh token');
            }
        } catch (error) {
            console.error('Error refreshing token:', error);
            logout();
        }
    };

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (authToken) {
                const decodedToken = jwtDecode(authToken);
                const expiryTime = decodedToken.exp * 1000; // Convert to milliseconds
                const currentTime = Date.now();
                const timeUntilExpiry = expiryTime - currentTime;

                if (timeUntilExpiry < 300000) { // Refresh if less than 5 minutes until expiry
                    refreshToken();
                } else {
                    // Set up the next check
                    setTimeout(checkTokenExpiration, Math.min(timeUntilExpiry - 300000, 2147483647));
                }
            }
        };

        checkTokenExpiration();
    }, [authToken]);

    const login = (token) => {
        localStorage.setItem('token', token);
        setAuthToken(token);
    };

    const logout = () => {
        localStorage.removeItem('token');
        setAuthToken(null);
    };

    return (
        <AuthContext.Provider value={{ authToken, login, logout, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';
import Newscenter from './Newscenter';
import Support from './Support';
import Dropbox from './Dropbox';
import Optischool from './Optischool';
import Manuals from './Manuals';

export default function Opticenter() {
    const { authToken } = useAuth();
    const { userProfile, setUserProfile } = useContext(UserContext);
    const [selectedCategory, setSelectedCategory] = useState('newscenter');
    const [isAdmin, setIsAdmin] = useState(false);

    // Category names for breadcrumb display
    const categoryNames = {
        'newscenter': 'Newscenter',
        'support': 'Supportforum',
        'dropbox': 'Dropbox',
        'optischool': 'Optischool',
        'manuals': 'Manuals'
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setUserProfile(data);
                    setIsAdmin(data.admin === 1);
                } else {
                    throw new Error('Failed to fetch user profile');
                }
            } catch (error) {
                console.error('Error fetching user profile:', error);
            }
        };
        if (authToken) {
            fetchUserProfile();
        }
    }, [authToken, setUserProfile]);

    const renderCategory = () => {
        switch (selectedCategory) {
            case 'newscenter':
                return <Newscenter />;
            case 'support':
                return <Support />;
            case 'dropbox':
                return <Dropbox />;
            case 'optischool':
                return <Optischool />;
            case 'manuals':
                return <Manuals />;
            default:
                return <Newscenter />;
        }
    };
    if (!isAdmin) {
        return <p>Access Denied: Admins Only</p>;
    }

    return (
        <div className="opticenter-container">
              <div className="opticenter-sidebar">
                <div className="opticenter-profile-section">
                    
                Hello,<h3 className='opticenter-top-name'> {userProfile.name}</h3>
                    <p>{isAdmin ? 'Admin' : 'User'}</p>
                    <p>{userProfile.companyName}</p>
                </div>
                <div className="opticenter-category-section">
                    <ul>
                        <li className='opticenter-menu-title' onClick={() => setSelectedCategory('newscenter')}>Newscenter</li>
                        <li className='opticenter-menu-title' onClick={() => setSelectedCategory('support')}>Supportforum</li>
                        <li className='opticenter-menu-title' onClick={() => setSelectedCategory('dropbox')}>Dropbox</li>
                        <li className='opticenter-menu-title' onClick={() => setSelectedCategory('optischool')}>Optischool</li>
                        <li className='opticenter-menu-title' onClick={() => setSelectedCategory('manuals')}>Manuals</li>
                    </ul>
                </div>
            </div>
            <div className="opticenter-content-section">
                {/* Breadcrumb section */}
                <div className="opticenter-breadcrumb">
                {/* Breadcrumb links */}
                <p>
                    {/* Clicking 'Opticenter' takes the user back to the default category (e.g., 'newscenter') */}
                    <span className="breadcrumb-link" onClick={() => setSelectedCategory('newscenter')}>
                        Opticenter
                    </span>
                    {' > '}
                    {/* Category name link */}
                    <span className="breadcrumb-link" onClick={() => setSelectedCategory(selectedCategory)}>
                        {categoryNames[selectedCategory]}
                    </span>
                </p>
            </div>
                {renderCategory()}
            </div>
        </div>
    );
}

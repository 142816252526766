import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyAEawz-ubixU0O4L6iCeRaYdhbrUK2Gars",
  authDomain: "optify-919b1.firebaseapp.com",
  projectId: "optify-919b1",
  messagingSenderId: "38999906270",
  appId: "1:38999906270:web:5663a41bee521179c76f4f"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
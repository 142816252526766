import React, { useState, useEffect } from 'react';

export default function NewsWriting({ id }) {
    const [newsTitles, setNewsTitles] = useState([]);
    const [selectedNews, setSelectedNews] = useState(null);
    const [newNews, setNewNews] = useState({ title: '', body: '' });
    const [editingNews, setEditingNews] = useState(null);
    const [showCreateForm, setShowCreateForm] = useState(false);

    useEffect(() => {
        fetchNewsTitles();
    }, []);

    const fetchNewsTitles = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/news-titles');
            const data = await response.json();
            setNewsTitles(data);
        } catch (error) {
            console.error('Error fetching news titles:', error);
        }
    };

    const fetchNewsById = async (newsId) => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + `/news/${newsId}`);
            const data = await response.json();
            setSelectedNews(data);
            setShowCreateForm(false);  // Hide create form when a news article is selected
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

    const handleCreate = async () => {
        try {
            const formattedBody = newNews.body.replace(/\n/g, '<br>');
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/news', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...newNews, body: formattedBody, creator: id })
            });

            if (response.ok) {
                const data = await response.json();
                setNewsTitles([...newsTitles, { id: data.id, title: data.title }]);
                setNewNews({ title: '', body: '' });
                setShowCreateForm(false);  // Hide create form after creating news
            } else {
                console.error('Failed to create news:', response.statusText);
            }
        } catch (error) {
            console.error('Error creating news:', error);
        }
    };

    const handleUpdate = async (newsId, updatedNews) => {
        try {
            const formattedBody = updatedNews.body.replace(/\n/g, '<br>');
            const response = await fetch(process.env.REACT_APP_SITE_URL + `/news/${newsId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...updatedNews, body: formattedBody })
            });

            if (response.ok) {
                setNewsTitles(newsTitles.map(item => (item.id === newsId ? { id: newsId, title: updatedNews.title } : item)));
                setSelectedNews(updatedNews);
                setEditingNews(null);
            } else {
                console.error('Failed to update news:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating news:', error);
        }
    };

    const handleDelete = async (newsId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/news/${newsId}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                setNewsTitles(newsTitles.filter(item => item.id !== newsId));
                setSelectedNews(null);
            } else {
                console.error('Failed to delete news:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting news:', error);
        }
    };

    return (
        <div className="newswriting-admintasks-content">
            <h2>News Management</h2>
            <div className="newswriting-container">
                <div className="newswriting-news-list">
                    <button onClick={() => setShowCreateForm(true)} className="newswriting-new-article-button">New Article</button>
                    {newsTitles.map((item) => (
                        <div key={item.id} onClick={() => fetchNewsById(item.id)} className="newswriting-news-title">
                            {item.title}
                        </div>
                    ))}
                </div>
                <div className="newswriting-content">
                    {showCreateForm ? (
                        <div className="newswriting-new-news">
                            <h2>Create New News</h2>
                            <input
                                type="text"
                                placeholder="Title"
                                value={newNews.title}
                                onChange={(e) => setNewNews({ ...newNews, title: e.target.value })}
                                className="newswriting-input"
                            />
                            <textarea
                                placeholder="Body"
                                value={newNews.body}
                                onChange={(e) => setNewNews({ ...newNews, body: e.target.value })}
                                className="newswriting-textarea"
                                style={{ height: '400px', fontSize: '16px' }} // Inline-stil for større tekstområde
                            />
                            <button onClick={handleCreate} className="newswriting-button">Create</button>
                        </div>
                    ) : (
                        selectedNews && (
                            <div className="newswriting-selected-news">
                                {editingNews ? (
                                    <div>
                                        <input
                                            type="text"
                                            value={editingNews.title}
                                            onChange={(e) => setEditingNews({ ...editingNews, title: e.target.value })}
                                            className="newswriting-input"
                                        />
                                        <textarea
                                            value={editingNews.body}
                                            onChange={(e) => setEditingNews({ ...editingNews, body: e.target.value })}
                                            className="newswriting-textarea"
                                        />
                                        <button onClick={() => handleUpdate(selectedNews.id, editingNews)} className="newswriting-button">Save</button>
                                        <button onClick={() => handleDelete(selectedNews.id)} className="newswriting-delete-button">Delete</button>
                                    </div>
                                ) : (
                                    <div>
                                        <h3>{selectedNews.title}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: selectedNews.body }} />
                                        <button onClick={() => setEditingNews(selectedNews)} className="newswriting-button">Edit</button>
                                        <button onClick={() => handleDelete(selectedNews.id)} className="newswriting-delete-button">Delete</button>
                                    </div>
                                )}
                            </div>
                        )
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useState } from 'react';

const FileDetailsInfo = ({
  fileDetails,
  dealerCredit,
  tuningToolOptions,
  isAdmin,
  isTuner,
  updatePaymentStatus,
}) => {
  const [showEditModal, setShowEditModal] = useState(false);

  return (
    <>
      <div className="info-box">
        <p className="detailtextmedium">
          <b>{fileDetails.dealerName}<br /></b> {dealerCredit} credits left<br />
        </p>
        <p className="detailtext"><b>Tuningtool:</b> {tuningToolOptions[fileDetails.tooltype]}</p>
        <p className="detailtext"><b>Uploaded:</b> {new Date(fileDetails.created).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.created).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
        <p className="detailtext"><b>Modified:</b> {new Date(fileDetails.updated).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.updated).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
        <p className="detailtext"><b>Charged:</b> {fileDetails.charged}</p>
      </div>

      <div className="info-box">
        {fileDetails.country}
        {fileDetails.credit}
        <p className="detailtext"><b>Customer info:</b></p>
        <p className="detailtext">{fileDetails.customername}</p>
        <p className="detailtext">{fileDetails.customeraddress}</p>
        <p className="detailtext">{fileDetails.customerphone}</p>
        <p className="detailtext">{fileDetails.customeremail}</p>
        <p className="detailtext">{fileDetails.customernotes}</p>
        <p className="detailtext">
          <b>Paid:</b>
          <span className={`status-box ${fileDetails.paid === 1 ? 'paid' : 'not-paid'}`}>
            {fileDetails.paid === 1 ? '✔' : '✖'}
          </span>
          {!(isAdmin || isTuner) && (
            <button className="edit-status-button" onClick={() => setShowEditModal(true)}>Edit</button>
          )}
        </p>

        {showEditModal && (
          <div className="modal-background" onClick={() => setShowEditModal(false)}>
            <div className="uploadconfirmation" onClick={e => e.stopPropagation()}>
              <div className="modal-header">
                <button className="close-btn" onClick={() => setShowEditModal(false)}>CLOSE</button>
              </div>
              <h3>Edit Payment Status</h3>
              <button
                className="confirm-button"
                onClick={() => updatePaymentStatus(fileDetails.id, 1)}
              >
                Paid
              </button>
              <button
                className="decline-button"
                onClick={() => updatePaymentStatus(fileDetails.id, 0)}
              >
                Not Paid
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default FileDetailsInfo;

// Optischool.js
import React from 'react';

export default function Optischool() {
    return (
        <div>
            <h2>Optischool</h2>
            <p>This is the Optischool content.</p>
        </div>
    );
}

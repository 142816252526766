import React, { useState, useEffect } from 'react';

export default function Manuals() {
    const [categories, setCategories] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState(null); // Tracks selected category
    const [selectedBox, setSelectedBox] = useState(null); // Tracks the clicked content box (subcategory)
    const [fileList, setFileList] = useState([]); // List of files from the selected folder
    const [selectedFile, setSelectedFile] = useState(null); // Tracks the selected file for preview
    const [isAdmin, setIsAdmin] = useState(true); // Replace with actual admin check

    useEffect(() => {
        fetchCategories();
    }, []);

    // Dummy categories with manuals and files
    const fetchCategories = async () => {
        const dummyCategories = [
            { id: 1, title: 'Car' },
            { id: 2, title: 'Truck' },
            { id: 3, title: 'Construction' },
            { id: 4, title: 'Diagnosis' },
            { id: 5, title: 'Agri' },
            { id: 6, title: 'Filecheck' },
            { id: 7, title: 'New test' },
            { id: 8, title: 'Checklist' },
            { id: 9, title: 'Dette for testing' },
            { id: 10, title: 'Dette og' }
        ];
        setCategories(dummyCategories);
    };

    // Function to fetch files from a folder dynamically, including subfolders
    const fetchFilesFromFolder = async (category, subcategory) => {
        try {
            const folderUrl = `${process.env.REACT_APP_SITE_URL}/opticenter/manuals/${category}/${subcategory}`;
            const response = await fetch(folderUrl);

            if (!response.ok) {
                throw new Error(`Error fetching files: ${response.statusText}`);
            }

            const files = await response.json(); // Assuming your API returns a JSON list of files
            setFileList(files);
            setSelectedFile(null); // Reset the selected file when switching subcategories
        } catch (error) {
            console.error("Error fetching files:", error);
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setSelectedBox(null); // Reset the selected subcategory when a new category is selected
        setFileList([]); // Clear file list when switching categories
        setSelectedFile(null); // Clear selected file when switching categories
    };

    const handleBoxClick = (boxTitle) => {
        setSelectedBox(boxTitle); // Set the selected subcategory (content box)
        fetchFilesFromFolder(selectedCategory.title.toLowerCase(), boxTitle.toLowerCase()); // Fetch files based on selected category and subcategory
    };

    const handleFileClick = (file) => {
        setSelectedFile(file); // Always display files inline
    };

    // Object mapping categories to their specific content boxes
    const categoryBoxes = {
        "Car": [
            { title: "LOCATION", description: "Files related to location" },
            { title: "HOWTO", description: "How-to guides" },
            { title: "TOOLS", description: "Tool manuals" },
            { title: "DELETES", description: "Deleted files" },
            { title: "DISCONNECT", description: "Disconnection procedures" }
        ],
        "Truck": [
            { title: "ACM", description: "ACM related files" },
            { title: "DPF Deletes", description: "DPF Delete guides" },
            { title: "Engine", description: "Engine manuals" },
            { title: "EGR Deletes", description: "EGRrelated files" },
            { title: "UREA Deletes", description: "UREA Delete guides" },
            { title: "Engine", description: "Engine manuals" }
        ],
        "Construction": [
            { title: "LOCATION", description: "Location guides for construction" },
            { title: "MAINTENANCE", description: "Maintenance manuals" },
            { title: "EGR Deletes", description: "EGR related files" },
            { title: "UREA Deletes", description: "UREA Delete guides" },
            { title: "DPF Deletes", description: "DPF Delete guides" }
        ],

        "Diagnosis": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "How to reset", description: "How to reset" },
            { title: "How to read codes", description: "Guides for diagnostic procedures" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }
        ],

        "Agri": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "Engine", description: "Engine manuals" },
            { title: "EGR Deletes", description: "EGRrelated files" },
            { title: "UREA Deletes", description: "UREA Delete guides" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }
        ],
        "Filecheck": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "TOOLS", description: "Tool manuals" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }
        ],
        "New test": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }
        ],
        "Checklist": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "LOCATION", description: "Files related to location" },
            { title: "HOWTO", description: "How-to guides" },
            { title: "TOOLS", description: "Tool manuals" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }
        ],
        "Dette for testing": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "DPF Deletes", description: "DPF Delete procedures" },
            { title: "Engine", description: "Engine manuals" },
            { title: "EGR Deletes", description: "EGRrelated files" }
        ],
        "Dette og": [
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "Diagnostic Procedures", description: "Guides for diagnostic procedures" },
            { title: "DPF Deletes", description: "DPF Delete procedures" }

        ]
    };

    return (
        <div>
            <h2>Manuals</h2>

            {/* Main Layout: Sidebar (Left) and Content in the middle */}
            <div className="manuals-center-layout">
                {/* Sidebar Menu */}
                <div className="manuals-center-sidebar">
                    <div className="manuals-center-search">
                        Search all manuals
                        <input
                            type="text"
                            placeholder="Search manuals..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </div>
                    <h3>Categories</h3>
                    {categories
                        .filter(cat => cat.title.toLowerCase().includes(searchTerm.toLowerCase()))
                        .map(category => (
                            <div key={category.id} className="manuals-center-category">
                                <div
                                    className="manuals-center-category-title"
                                    onClick={() => handleCategoryClick(category)}
                                >
                                    {category.title}
                                </div>
                            </div>
                        ))
                    }

                    {isAdmin && (
                        <div className="manuals-center-admin">
                            <button onClick={() => alert('Admin area for managing categories and manuals')}>Admin</button>
                        </div>
                    )}
                </div>

                {/* Content Area */}
                <div className="manuals-center-content">
                    {/* Breadcrumb Navigation */}
                    <div className="manuals-center-breadcrumb">
                        <span onClick={() => setSelectedCategory(null)} className="breadcrumb-link">
                            Manuals
                        </span>
                        {selectedCategory && (
                            <>
                                {' > '}
                                <span onClick={() => setSelectedBox(null)} className="breadcrumb-link">
                                    {selectedCategory.title}
                                </span>
                            </>
                        )}
                        {selectedBox && (
                            <>
                                {' > '}
                                <span>{selectedBox}</span>
                            </>
                        )}
                    </div>

                    {/* Show content boxes if a category is selected but no subcategory is clicked */}
                    {selectedCategory && !selectedBox ? (
                        <div className="manuals-center-box-layout">
                            {categoryBoxes[selectedCategory.title]?.map((box, index) => (
                                <div
                                    key={index}
                                    className="manuals-center-box"
                                    onClick={() => handleBoxClick(box.title)} // Clicking a content box
                                >
                                    <h4>{box.title}</h4>
                                    <p>{box.description}</p>
                                </div>
                            ))}
                        </div>
                    ) : selectedBox ? (
                        <div>
                            <h3>{selectedBox} Files</h3>
                            {/* Render files in a responsive grid */}
                            {fileList.length > 0 ? (
                                <ul className="manuals-filemanager-file-list">
                                    {fileList.map((file, index) => (
                                        <li key={index} onClick={() => handleFileClick(file)}>
                                            {file.name}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>No files found in this folder. (Anders har fucka opp et eller anna)</p>
                            )}

                            {/* Inline file viewer for all file types */}
                            {selectedFile && (
                                <div className="manuals-filemanager-file-viewer">
                                    <h4>Viewing: {selectedFile.name}</h4>
                                    <object
                                        data={selectedFile.url}
                                        type="application/pdf" // Default type as 'application/pdf'
                                        width="100%"
                                        height="600px"
                                    >
                                        <iframe
                                            src={selectedFile.url}
                                            width="100%"
                                            height="600px"
                                        >
                                            <p>Unable to display file. <a href={selectedFile.url}>Download</a></p>
                                        </iframe>
                                    </object>
                                </div>
                            )}
                        </div>
                    ) : (
                        <p>Select a category to view its content.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';

const UserCreation = ({ authToken }) => {
    const [memberData, setMemberData] = useState({ name: '', email: '', phone: '', country: 'NO' });
    const [dealerData, setDealerData] = useState({ name: '', legal: '', vat: '', address: '', city: '', postal: '', type: 'OPTI' });
    const [members, setMembers] = useState([]);
    const [dealers, setDealers] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMembers();
        fetchDealers();
    }, []);

    const handleMemberChange = (e) => {
        const { name, value } = e.target;
        setMemberData({ ...memberData, [name]: value });
    };

    const handleDealerChange = (e) => {
        const { name, value } = e.target;
        setDealerData({ ...dealerData, [name]: value });
    };

    const handlePost = async (url, body, callback) => {
        setLoading(true);
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(body),
            });

            if (response.ok) {
                const data = await response.json();
                callback(data);
            } else {
                const errorData = await response.json();
                console.error('Failed to post', errorData);
            }
        } catch (error) {
            console.error('Error posting:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMembers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-but-get-members`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = await response.json();
            setMembers(data);
        } catch (error) {
            console.error('Error fetching members:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchDealers = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-but-get-dealers`, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
            });
            const data = await response.json();
            setDealers(data);
        } catch (error) {
            console.error('Error fetching dealers:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (id, type) => {
        const confirmation = window.confirm(`Are you sure you want to delete this ${type}?`);
        if (confirmation) {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SITE_URL}/delete-member/${id}`, {
                    method: 'DELETE',
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });
                if (response.ok) {
                    if (type === 'member') {
                        fetchMembers();
                    } else {
                        fetchDealers();
                    }
                } else {
                    const errorData = await response.json();
                    console.error('Failed to delete', errorData);
                }
            } catch (error) {
                console.error('Error deleting:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleMemberSubmit = (e) => {
        e.preventDefault();
        handlePost(`${process.env.REACT_APP_SITE_URL}/create-member`, memberData, (data) => {
            fetchMembers(); // Fetch updated list of members
        });
    };

    const handleDealerSubmit = (e) => {
        e.preventDefault();
        handlePost(`${process.env.REACT_APP_SITE_URL}/create-dealer`, dealerData, (data) => {
            fetchDealers(); // Fetch updated list of dealers
        });
    };

    return (
        <div className="user-creation-container">
            {loading && <div className="loader">Loading...</div>}
            <div className="user-creation-column">
                <h2>Create Member</h2>
                <form onSubmit={handleMemberSubmit} className="user-form">
                    <input type="text" name="name" placeholder="Name" value={memberData.name} onChange={handleMemberChange} required />
                    <input type="email" name="email" placeholder="Email" value={memberData.email} onChange={handleMemberChange} required />
                    <input type="text" name="phone" placeholder="Phone" value={memberData.phone} onChange={handleMemberChange} required />
                    <select name="country" value={memberData.country} onChange={handleMemberChange} required>
                        <option value="NO">Norsk</option>
                        <option value="SE">Svensk</option>
                        <option value="NA">Engelsk</option>
                    </select>
                    <button type="submit">Create Member</button>
                </form>
                <h2>Delete Members</h2>
                <ul>
                    {members.map(member => (
                        <li
                            key={member.id}
                            className="member-item"
                            onClick={() => handleDelete(member.id, 'member')}
                        >
                            {member.name} ({member.email})
                        </li>
                    ))}
                </ul>
            </div>
            <div className="user-creation-column">
                <h2>Create Dealer</h2>
                <form onSubmit={handleDealerSubmit} className="user-form">
                    <input type="text" name="name" placeholder="Name" value={dealerData.name} onChange={handleDealerChange} required />
                    <input type="text" name="legal" placeholder="Legal" value={dealerData.legal} onChange={handleDealerChange} required />
                    <input type="text" name="vat" placeholder="VAT" value={dealerData.vat} onChange={handleDealerChange} required />
                    <input type="text" name="address" placeholder="Address" value={dealerData.address} onChange={handleDealerChange} required />
                    <input type="text" name="city" placeholder="City" value={dealerData.city} onChange={handleDealerChange} required />
                    <input type="text" name="postal" placeholder="Postal" value={dealerData.postal} onChange={handleDealerChange} required />
                    <select name="type" value={dealerData.type} onChange={handleDealerChange} required>
                        <option value="OPTI">OPTI</option>
                        <option value="WL">WL</option>
                    </select>
                    <button type="submit">Create Dealer</button>
                </form>
                <h2>Delete Dealers</h2>
                <ul>
                    {dealers.map(dealer => (
                        <li
                            key={dealer.id}
                            className="dealer-item"
                            onClick={() => handleDelete(dealer.id, 'dealer')}
                        >
                            {dealer.name} ({dealer.email}) - {dealer.opti ? 'OPTI' : ''} {dealer.wl ? 'WL' : ''}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default UserCreation;

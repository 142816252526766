import React, { useEffect } from 'react';

export default function Tossite({ onClose, did }) {

    useEffect(() => {
    }, [did]);

    return (
        <div className="adminmodal-background">
            <div className="adminmodal-content" onClick={e => e.stopPropagation()}>
                <div className="modal-header">
                    <button className="close-btn" onClick={onClose}>CLOSE</button>
                </div>
                <h2>TERMS OF SERVICE</h2>

                        <div className="tos-text-header">Støttekanaler:</div>
                        <ul><div className="tos-text">
                            <li>Telefonstøtte</li>
                            <li>Database / Supportforum (Inneholder nyttig informasjon om kjøretøy)</li>
                            <li>E-post (fileservice@optifiles.no)</li>
                            <li>Chat</li>
                            </div>
                        </ul>

                        <div className="tos-text-header">Åpningstider for Filservice:</div>
                        <ul><div className="tos-text">Åpningstider for Filservice:
                            <li>Man-Fre: 09:00-19:00 (CET/CEST)</li>
                            <li>Lørdag: 10:00-14:00 (CET/CEST)</li>
                            <li>Søndag: Stengt</li>
                            <li>* Begrenset service på kvelder/helger, men mulighet for booking av OOH Tuner</li>
                            </div>
                        </ul>

                        <div className="tos-text-header">Tjenester:</div>
                        <ul><div className="tos-text">
                            <li>Eco (Med fokus på drivstoffbesparelse uten å nødvendigvis hente ut så mye effekt)</li>
                            <li>Opti 'Stage 1' (Fokus på kjørbarhet og drivstoffbesparelse, her økes effekten til det mest optimale, hvor drivstoffbesparelse og er satt i fokus)</li>
                            <li>Power'Stage 2' (Utnyttter potensialet i motoren, å henter ut alt tilgjengelig effekt og dreiemoment innenfor det motoren er kapabel/konstruert for)</li>
                            <li>EGR løsninger og sletting av koder</li>
                            <li>DPF løsninger og sletting av koder</li>
                            <li>DECAT / O2 off og sletting av koder</li>
                            <li>EPA / FIXED Vmax (Fastsatt fartpssperre)</li>
                            <li>HYBRIDTURBO kalibrering</li>
                            <li>Gearbox Tune (Kalibrering av girkasse, for å tilatte høyere dreiemoment, mere trykk på plater. etc.)</li>
                            <li>NSL / VMAX OFF (Fjerning av fartssperre)</li>
                            <li>Coldstart off (Deaktivering av kaldstart og oppvarming av katalysator)</li>
                            <li>Start/Stop off (Deaktivering av automatisk start/stop'Euro5/6')</li>
                            <li>Swirlflaps off (Deaktivering av swirl/intake flaps)</li>
                            <li>TVA off (ThrottleValveActuator sletting på dieselbiler)</li>
                            <li>Sletting av alle typer MIL koder relatert til ECU</li>
                            </div>
                        </ul>
                        <div className="tos-text-red">Vi leverer vanligvis ikke crackles, pops & bangs, immo off løsninger, men i noen tilfeller kan vi tilby testløsninger med begrensede gjentakelser / support.</div>


                        <div className="tos-text-header">Ikke-tuning MIL koder</div>
                        <div className="tos-text">Vi vil ikke avgjøre om en ikke-tuning MIL kode er trygg for kjøretøyet eller ikke. Som sertifisert forhandler og med kjøretøyet foran deg, er det din avgjørelse, eller hvis du ikke vet, gjør det rett og slett ikke.</div>
                        <div className="tos-text">Vær oppmerksom på at for eksempel en bil som produserer 50hk mindre på grunn av en ødelagt throttlebody, fortsatt vil produsere 50hk mindre selv når den er tunet, sammen med en kode sletting. Fjerning av ikke-tuning MIL koder er ikke en løsning for alt, så bruk det med omhu. I tillegg kan det i mange tilfelle vanskeliggjøre feilsøking</div>

                        <div className="tos-text-header">DPF Filer</div>
                        <div className="tos-text">Kjent for å ha problemer hvis DPF-resets ikke utføres med et diagnostisk verktøy før filen blir flashet. Modfil endrer ikke overdrevent høye DPF live-data verdier som utløser advarselslys på dashbordet, tap av cruisekontroll, etc.</div>
                        <div className="tos-text">Verdiene tilbakestilles ikke automatiskt ved å legge inn modfil, dette må gjøres med diagnostisk skanneverktøy før modfil skrives. Hvis det er et blinkende DPF-lys på dashbordet som original, betyr det at DPF-sotbelastningsinnholdet er høyt, vil det forbli blinkende med modfilen skrevet. Diagnostisk arbeid er 90% av jobben, modfilen er de siste 10%.</div>

                        <div className="tos-text-header">EGR Filer</div>
                        <div className="tos-text">Hvis Optifiles instruerer at EGR må kobles fra, MÅ den kobles fra, hvis det ikke står noe, må den forbli koblet til (det normale). I enkelte tilfeller kan det utløses åpen krets MIL-koder og limp mode av manglende motstand tilbake til ECUen. Så i endel tilfeller kan ikke egr-ventil fysisk fjernes.  Hvis en EGR-sletting er gjort på en bil der EGR-ventilen sitter fastlåst i åpen stilling, kan en blokkering også være nødvendig, dette merker man ofte dersom bilens nødmodus vedvarer, og eller man får feil på eks. tap av ladetrykk / feil luftmasse / feil på luftmassemåler.</div>

                        <div className="tos-text-header">SCR/DEF/ADBLUE FILES</div>
                        <div className="tos-text">Hvis kjøretøyet viser "300 km igjen til motoren ikke starter", betyr dette at det lave AdBlue-varselet har blitt ignorert for lenge og at startsperren planlegger å stoppe ECUen fra å starte. Denne informasjonen er lagret i startsperredelen av ECUen/nøkkelfobben, og AdBlue-løsningen vil ikke fungere på dette tidspunktet, det er for sent. ECUen må tilbakestilles med diagnose verktøy for resetting av AdBlue-systemet.</div>

                        <div className="tos-text-header">Fil-Redo Politikk</div>
                        <div className="tos-text">Ofte resulterer forsøk på å reparere et kjøretøy med flere problemer gjennom filen ofte i endeløse forsøk på å fikse det, med flere og flere koder som dukker opp. Alle redos vil være begrenset når det virker umulig og ikke løsbart ved fil. På det tidspunktet vil jobben bli ansett som avsluttet.</div>

                        <div className="tos-text-header">Creditback</div>
                        <div className="tos-text">Hvis et kjøretøy settes tilbake til original stand innen 30 dager, kan du søke om refusjon av credits. Hvis du av en eller annen grunn ikke er i stand til å sette det tilbake til original innen 30 dager, må du gi beskjed om dette i dialogen INNEN 30 dager. En kopi av loggen fra verktøyene må sendes i dialog, samt årsak til at credit ønskes refundert. (dette er ikke mulig med masterfiler).</div>

                        <div className="tos-text-header">Opplasting av filer</div>
                        <div className="tos-text">Når du laster opp filer, prøv å fylle ut så riktig informasjon som mulig, noen kjøretøy kan mangle i rullegardinmenyene, i så fall, skriv så detaljerte notater som mulig.</div>

                        <div className="tos-text-header">Dialog om fil / Tilbakemelding</div>
                        <div className="tos-text">Dialogen brukes til all videre kommunikasjon angående det spesifikke kjøretøyet og alle videre utfordringer, nye filer, etc. en. Dersom et kjøretøy har problemer, prøv å beskriv symptomer og hendelsesforløp så godt som mulig, få med relevante feilkoder, livedata og så mye informasjon om hva som evt. utløser problemet du kan. Husk at vi på filservice kun vet det du forklarer i dialog, er sjelden noe logisk forklaring bare ved å se på filen til ECU</div>

                        <div className="tos-text-header">Revidert / Redo av fil</div>
                        <div className="tos-text">Når du trenger en revidert fil, last alltid opp slavefilen som du har tenkt å få den reviderte filen for. Dette er for riktig kryptering/dekryptering formål, og vil spare tid i begge ender. En redo vil koste ekstra credits hvis det er en endring av hva som opprinnelig ble lastet opp. Hvis behovet for en revidert fil er på grunn av en feil med filen, er filen alltid gratis, uten begrensning av dager. Hvis en bil har blitt satt tilbake til original hos forhandler/verksted, vil ny fil i noen tilfeller koste noen credits dersom den er markant endret og krever ny utvikling. Det er forøvrig ekstremt viktig at du tar en fersk read fra kjøretøyet og trykker på redo i opplastingen og legger inn kommentarer om dette.</div>

                        <div className="tos-text-header">Fil on hold</div>
                        <div className="tos-text">Fra tid til annen kan det være flere grunner til at optifiles trenger tilbakemelding fra forhandleren før de fortsetter med filen. I disse tilfellene vil filen bli satt på vent, og gi en varsling i Optifiles. En melding fra tuneren vil følge i dialogen, og gir deg muligheten til å svare på situasjonen om nødvendig.</div>

                        <div className="tos-text-header">Support</div>
                        <div className="tos-text">Dersom en fil settes i statusen support, så vil alle forhandlere som har supportstatus se filen og kunne bistå med råd og veiledning. Det kan skje at enkelte kjøretøy opplever problemer som ikke er direkte fil-relatert, hvor flere sitter på relevant erfaring og har tips og veiledning. Om vi føler at Optifiles ikke har tilstrekkelig kunnskap og problemet ikke ligger i fil levert av Optifiles, settes filen i supportstatus slik at flere kan bidra med å løse evt. problem.</div>

                        <div className="tos-text-header">KJØRETØYANSVAR</div>
                        <div className="tos-text">Det er uunngåelig at det av og til vil går galt. Hvis det er tuningverktøyet som gjør feil, kan vi forvente at produsentene prøver å løse det, men vær oppmerksom på at de svært usannsynlig vil dekke noen kostnader. Vi vil sålangt det lar seg gjøre bistå og hjelpe, men du må være klar over at vi ikke garanterer for dine feil eller verktøyfeil. Fil i seg selv, klarer ikke og gjøre permanent skade.</div>

                        <div className="tos-text-header">OBD PROGRAMMERING - RISIKO</div>
                        <div className="tos-text">På en daglig basis kan eventuelle problemer med OBD-programmering normalt løses, permanente feil er få og langt mellom. Når ting går galt, er det normalt en av følgende årsaker:</div>
                        <ul>
                            <li>Lav kjøretøybatterispenning eller manglende bruk av en batterilader</li>
                            <li>Dårlig batteri på pc, virusskanning som stater, en skjermbeskytter som slår seg på, eller pc som går i standby</li>
                            <li>Forårsaker en spenningspike ved å åpne og lukke døren</li>
                            <li>Verktøyprotokollfeil, eller defekt verktøy</li>
                            <li>Filfeil, som er sjelden og normalt vil føre til problemer med kjøretøyet, snarere enn å ødelegge ecu</li>
                        </ul>

                        <div className="tos-text-header">BENCH PROGRAMMERING AV ECU'S - RISIKO</div>
                        <div className="tos-text">De fleste av disse jobbene går gjennom uten noen problemer overhodet, men det er flere potensielle farer enn en rett frem OBD-jobb, som kan resultere i en død ECU, disse inkluderer:</div>
                        <ul>
                            <li>Skade på ECU eller ledningsnett når du fjerner ECU</li>
                            <li>Skade på ECU når du fjerner lokket</li>
                            <li>Skade på ECU når loddetilkoblinger eller boot-ledninger (overoppheting av komponenter, loddesprut, etc)</li>
                            <li>Skade på ECU når du fjerner loddetilkoblinger eller boot-ledninger (overoppheting av komponenter, løfting av pads eller spor, loddesprut, etc)</li>
                            <li>Defekt eller korrupt fil (usannsynlig å forårsake av en dårlig readout)</li>
                            <li>Feil protokoller</li>
                            <li>Defekt verktøy eller skadede kontakter</li>
                        </ul>
                        <div className="tos-text">Tips - For å redusere programmeringsrisikoen, sjekk alltid og les verktøyprotokollinstruksjonene, selv om det er en kjøretøytype du har programmert før, da de blir oppdatert regelmessig.</div>
            </div>
        </div>
    );
}

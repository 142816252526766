// Dropbox.js
import React from 'react';

export default function Dropbox() {
    return (
        <div>
            <h2>Dropbox</h2>
            <p>DROPBOX APIET MÅ HER</p>
        </div>
    );
}
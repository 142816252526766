import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Juster stien til den faktiske plasseringen

export default function TopBar({ openDatabaseModal, openUploadModal }) {
    const [pendingFilesCount, setPendingFilesCount] = useState(0); // State for antall filer i kø
    const nuh = new Date().toLocaleTimeString('no-NO', { timeZone: 'Europe/Oslo' });

    // Funksjon for å hente antall filer i kø
    const fetchPendingFilesCount = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploads/pending-count`);
            const data = await response.json();
            if (response.ok) {
                setPendingFilesCount(data.pendingCount); // Sett antallet fra API-responsen
            } else {
                console.error('Failed to fetch pending files count:', data.message);
            }
        } catch (error) {
            console.error('Error fetching pending files count:', error);
        }
    };

    // Hent data når komponenten monteres
    useEffect(() => {
        fetchPendingFilesCount();
    }, []);

    // Dynamisk klassenavn basert på antall filer i kø
    const getQueueClass = () => {
        if (pendingFilesCount === 0) {
            return 'queue-none';
        } else if (pendingFilesCount >= 1 && pendingFilesCount <= 3) {
            return 'queue-low';
        } else if (pendingFilesCount >= 4 && pendingFilesCount <= 5) {
            return 'queue-medium';
        } else if (pendingFilesCount >= 6) {
            return 'queue-high';
        }
    };

    return (
        <nav>
            <img src={logo} alt="Logo" className="logo" />
            <ul>
                <li><Link to="/" onClick={openUploadModal}>UPLOAD</Link></li>
                <li><Link to="/">FILEPORTAL</Link></li>
                <li><Link to="/" onClick={openDatabaseModal}>DATABASE</Link></li>
                <li><Link to="/opticenter">OPTICENTER</Link></li>
            </ul>
            <div className="queuecontainer">            
                <div className={`queuetext ${getQueueClass()}`}>
                    {pendingFilesCount} FILES IN QUEUE {nuh}
                </div>
            </div>
        </nav>
    );
}

import React, { useState, useEffect, useRef } from 'react';
import filecomupload from '../assets/filecomupload.png';
import filecomredo from '../assets/filecomredo.png';
import filecomcredit from '../assets/filecomcredit.png';

function FileDetails({ fileId, onClose, isAdmin, isTuner, userProfile }) {
  const [fileDetails, setFileDetails] = useState(null);
  const [dialogs, setDialogs] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [isInternal, setIsInternal] = useState(false);
  const [showForm, setShowForm] = useState(null);
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isUploadModPopupVisible, setIsUploadModPopupVisible] = useState(false);
  const [modFile, setModFile] = useState(null);
  const [masterFile, setMasterFile] = useState(null);
  const [gearboxFile, setGearboxFile] = useState(null);
  const [gearboxMasterFile, setGearboxMasterFile] = useState(null);
  const [textInput1, setTextInput1] = useState('');
  const [textInput2, setTextInput2] = useState('');
  const [selectedFilecomId, setSelectedFilecomId] = useState(null); // New state for selected filecom id
  const [vehicleData, setVehicleData] = useState(null);
  const [dealerCountry, setDealerCountry] = useState('');
  const [dealerCredit, setDealerCredit] = useState('');
  const [isEditRegModalVisible, setIsEditRegModalVisible] = useState(false);
  const [editRegValue, setEditRegValue] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);
  const [copySuccess2, setCopySuccess2] = useState(false);
  const [companyLevel, setCompanyLevel] = useState({ level: 0, baseCredits: 0 });
  const [regnumMatches, setRegnumMatches] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modfileMatchesByDialog, setModfileMatchesByDialog] = useState({});
  const [showMatches, setShowMatches] = useState(false);
  const [isAdvancedVisible, setIsAdvancedVisible] = useState(false); // State to manage visibility of the advanced options
  const [selectedOptions, setSelectedOptions] = useState({}); // State to manage selected options
  const [estimatedCredits, setEstimatedCredits] = useState(0);
  const initialOptions = useRef({}); // Bruk useRef for å lagre initiale alternativer
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreditBackModal, setShowCreditBackModal] = useState(false);

  const handleCopyToClipboard = (e) => {
    e.stopPropagation(); // Stopper klikket fra å utløse andre onClick-hendelser
    navigator.clipboard.writeText(fileDetails.regnum).then(() => {
      setCopySuccess2(true);
      setTimeout(() => setCopySuccess2(false), 2000); // Skjuler notifikasjonen etter 2 sekunder
    }).catch(err => {
      console.error('Failed to copy license plate: ', err);
    });
  };


  const handleAdvancedToggle = (dialog) => {
    if (!isAdvancedVisible) {
        const options = {
            tuning_type: dialog.fileOptions.tuning_type_value || '', // Sørger for at tuning_type settes korrekt
            egr: dialog.fileOptions.egr === 1,
            dpf: dialog.fileOptions.dpf === 1,
            adblue: dialog.fileOptions.adblue === 1,
            nsl: dialog.fileOptions.nsl === 1,
            o2: dialog.fileOptions.o2 === 1,
            epa: dialog.fileOptions.epa === 1,
            hybridturbo: dialog.fileOptions.hybridturbo === 1,
            gearbox: dialog.fileOptions.gearbox === 1,
            coldstart: dialog.fileOptions.coldstart === 1,
            startstop: dialog.fileOptions.startstop === 1,
            popsbangs: dialog.fileOptions.popsbangs === 1,
            burbles: dialog.fileOptions.burbles === 1,
            swirlflaps: dialog.fileOptions.swirlflaps === 1,
            tva: dialog.fileOptions.tva === 1,
            priority: dialog.fileOptions.priority_value || false,
        };

        setSelectedOptions(options);
        initialOptions.current = options;
    }
    setIsAdvancedVisible(!isAdvancedVisible);
};

const handleOptionChange = (optionName, value) => {
  setSelectedOptions((prevOptions) => {
      const newOptions = {
          ...prevOptions,
          [optionName]: value,
      };

      let credits = calculateEstimatedCredits(newOptions);
      if (!value && initialOptions.current[optionName]) {
          credits -= calculateOptionCredits(optionName);
      }

      setEstimatedCredits(credits);

      return newOptions;
  });
};

const calculateOptionCredits = (optionName) => {
  const optionCosts = {
      egr: isCustomType ? 15 : 3,
      dpf: isCustomType ? 15 : 3,
      adblue: isCustomType ? 20 : 5,
      nsl: isCustomType ? 10 : 2,
      o2: 2,
      epa: 7,
      hybridturbo: 7,
      gearbox: 5,
      coldstart: 2,
      startstop: 2,
      popsbangs: 5,
      burbles: 5,
      swirlflaps: 2,
      tva: 2,
  };
  return optionCosts[optionName] || 0;
};


  const closeUploadModPopup = () => {
    setIsUploadModPopupVisible(false);
  };
  const handleToggleMatches = () => {
    setShowMatches(!showMatches);
  };

  const handleBoxClick = (id) => {
    setSelectedFileId(id);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedFileId(null);
  };

  const fetchCompanyLevel = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/calculate-credits/${fileDetails.mid}`);
      const data = await response.json();
      setCompanyLevel(data);
    } catch (error) {
      console.error('Error fetching company level:', error);
    }
  };

  useEffect(() => {
    if (fileDetails && fileDetails.mid) {
      fetchCompanyLevel();
    }
  }, [fileDetails]);

  const deductCredits = async (creditsToDeduct) => {
    try {
        console.log(`Deducting ${creditsToDeduct} credits from dealer ID: ${fileDetails.mid}`);

        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deduct-credits`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${userProfile.token}`,
            },
            body: JSON.stringify({
                id: fileDetails.mid,
                credits: creditsToDeduct,
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to deduct credits');
        }
        const data = await response.json();
        setDealerCredit(data.newCreditBalance);
    } catch (error) {
        console.error('Error deducting credits:', error);
        alert('Failed to deduct credits. Please try again.');
    }
};

const fetchVehicleData = async (regNum) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/getReg/${regNum}`);
    if (response.status === 404) {
      setVehicleData(null);
      return;
    }
    if (!response.ok) {
      throw new Error('Failed to fetch vehicle data');
    }
    const data = await response.json();
    setVehicleData(data);
  } catch (error) {
    setVehicleData(null);
  }
};

  useEffect(() => {
    if (fileDetails && fileDetails.regnum) {
      fetchVehicleData(fileDetails.regnum);
    }
  }, [fileDetails]);

  const fetchDealerDetails = async (dealerName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/dealer-details/${dealerName}`);
      if (!response.ok) {
        throw new Error('Failed to fetch dealer details');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching dealer details:', error);
      return null;
    }
  };
  useEffect(() => {
    if (fileDetails && fileDetails.dealerName) {
      fetchDealerDetails(fileDetails.dealerName).then(data => {
        if (data) {
          setDealerCountry(data.country);
          setDealerCredit(data.credit);
        }
      });
    }
  }, [fileDetails]);

  const handleUploadModSubmit = async (event, fileOptions) => {
    event.preventDefault();
    if (!textInput1.trim() || !textInput2.trim()) {
        alert('Både Dealerkommentar og Internkommentar må fylles ut.');
        return;
    }
    const creditsToDeduct = calculateEstimatedCredits(fileOptions);
    const modMessageWithCredits = `${textInput1.trim()} (Credits withdrawn: ${creditsToDeduct})`;
    const formData = new FormData();
    if (modFile) formData.append('modFile', modFile);
    if (masterFile) formData.append('masterFile', masterFile);
    if (gearboxFile) formData.append('gearboxFile', gearboxFile);
    if (gearboxMasterFile) formData.append('gearboxMasterFile', gearboxMasterFile);
    formData.append('fcid', selectedFilecomId);
    formData.append('textInput1', modMessageWithCredits);

    try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadModFiles`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Failed to upload files');
        }
        const data = await response.json();
        setDialogs(prevDialogs => prevDialogs.map(dialog => {
            if (dialog.id === selectedFilecomId) {
                return {
                    ...dialog,
                    modurl: data.modFileUrl || dialog.modurl,
                    modmasterurl: data.masterFileUrl || dialog.modmasterurl,
                    modgearurl: data.gearboxFileUrl || dialog.modgearurl,
                    modgearmasterurl: data.gearboxMasterFileUrl || dialog.modgearmasterurl,
                    modmessage: modMessageWithCredits, // Use the modified message here
                    isInitialUpload: data.isInitialUpload
                };
            }
            return dialog;
        }));

        await postInternalComment(textInput2);
        await deductCredits(creditsToDeduct);
        await setCreditWithdrawn(fileDetails.id, creditsToDeduct);
        await updateFileStatus(fileDetails.id, 2);
        await logCreditWithdrawal(fileDetails.mid, creditsToDeduct, fileDetails.dealerName, userProfile.name);
        setIsUploadModPopupVisible(false);
        setModFile(null);
        setMasterFile(null);
        setGearboxFile(null);
        setGearboxMasterFile(null);
        setTextInput1('');
        setTextInput2('');

        setFileDetails(prevDetails => ({
          ...prevDetails,
          status: 2, // Oppdater status til 'Done'
        }));


        if (data.isInitialUpload) {
            if (data.gearboxFileUrl) {
            }
        }
    } catch (error) {
        console.error('Error uploading files:', error);
        alert('Failed to upload files. Please try again.');
    }
};

    const setCreditWithdrawn = async (fileId, creditsWithdrawn) => {
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/set-credit-withdrawn`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${userProfile.token}`,
              },
              body: JSON.stringify({
                  fileId: fileId,
                  creditsWithdrawn: creditsWithdrawn,
              }),
          });
          if (!response.ok) {
              throw new Error('Failed to log credit withdrawal');
          }
      } catch (error) {
          console.error('Error logging credit withdrawal:', error);
      }
    };

    const postInternalComment = async (comment) => {
        try {
            const payload = {
                fileId: fileId,
                user: userProfile.name,
                message: comment,
                internal: 1,
                date: new Date().toISOString(),
            };

            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/postInternalComment`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userProfile.token}` },
                body: JSON.stringify(payload),
            });
            if (!response.ok) {
                throw new Error('Failed to post internal comment');
            }

            const data = await response.json();

            setDialogs(prevDialogs => [...prevDialogs, { ...payload, id: data.commentId }]);

        } catch (error) {
            console.error('Error posting internal comment:', error);
            alert('Failed to post internal comment. Please try again.');
        }
    };

    const updateFileStatus = async (fileId, status) => {
      console.log(`Starting to update file status. File ID: ${fileId}, New Status: ${status}`);

      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${fileId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status,
            tuner: userProfile.name,
            dealercheck: 1 // Setter dealercheck til 1
          }),
        });

        console.log(`Response status: ${response.status}`);

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error response from server:', errorData);
          throw new Error(`Failed to update file status. Server responded with status: ${response.status}`);
        }

        console.log('File status and dealercheck updated successfully.');
      } catch (error) {
        console.error('Error updating file status and dealercheck:', error);
        alert('Failed to update file status. Please try again.');
      }
    };

    const changeStatusDirectly = async (fileId, status) => {
      console.log(`Starting to update file status. File ID: ${fileId}, New Status: ${status}`);

      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${fileId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            status,
            tuner: userProfile.name,
            dealercheck: 1 // Setter dealercheck til 1
          }),
        });

        console.log(`Response status: ${response.status}`);

        if (!response.ok) {
          const errorData = await response.json();
          console.error('Error response from server:', errorData);
          throw new Error(`Failed to update file status. Server responded with status: ${response.status}`);
        }

        // Oppdater statusen lokalt uten å reloade siden
        setFileDetails(prevDetails => ({ ...prevDetails, status }));

        console.log('File status and dealercheck updated successfully.');
      } catch (error) {
        console.error('Error updating file status and dealercheck:', error);
        alert('Failed to update file status. Please try again.');
      }
    };







    const logCreditWithdrawal = async (mid, creditsWithdrawn, dealerName, username) => {
      const now = new Date();
      const formattedDate = now.toISOString().slice(0, 19).replace('T', ' ');
      const op_text = `${fileDetails.id}: ${username} withdrew -${creditsWithdrawn} credits from ${dealerName} (${dealerCredit - creditsWithdrawn} total)`;
      const payload = {
          d_mid: mid, // Dealer's member ID
          op_text,
          created: formattedDate,
      };
      try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/logCreditWithdrawal`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
          });
          if (!response.ok) {
              throw new Error('Failed to log credit withdrawal');
          }
      } catch (error) {
          console.error('Error logging credit withdrawal:', error);
          alert('Failed to log credit withdrawal. Please try again.');
      }
  };

  const transmissionOptions = {
    2: 'Auto',
    3: 'Manual',
    4: 'DSG',
  };

  const fuelOptions = {
    2: 'Diesel',
    3: 'Petrol',
    5: 'Hybrid',
  };

  const tuningToolOptions = {
    1: 'Autotuner',
    2: 'MMFlex',
    3: 'NewGenius',
    9: 'Unlisted - Other tool',
    10: 'Trasdata',
    11: 'Kess V3',
    12: 'K-Tag',
  };

  useEffect(() => {
    document.body.classList.add('no-scroll');
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, []);

  const handleClose = () => {
    onClose();
    window.location.reload();
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        onClose();
        window.location.reload();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onClose]);
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [dialogs]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [newMessage]);

  const handleTextareaInput = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '1px';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const getMessageType = (dialog) => {
    let classes = [];
    if (dialog.internal === 1) classes.push('internal-message');
    if (dialog.user === fileDetails.dealerName) classes.push('dealer-message');
    else classes.push('admin-message');
    if (dialog.rredo === 1) classes.push('rredo-message');
    if (dialog.rcredit === 1) classes.push('rcredit-message');
    if (dialog.url) classes.push('url-message');
    if (dialog.uploadurl) classes.push('uploadurl-message');
    if (dialog.uploaded === 1) classes.push('uploaded-message');
    return classes.join(' ');
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files);
    fileInputRef.current.files = event.target.files;
    setDropdownVisible(false);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    const dataTransfer = new DataTransfer();
    updatedFiles.forEach(file => dataTransfer.items.add(file));
    fileInputRef.current.files = dataTransfer.files;
  };

  function isImage(url) {
    return /\.(jpg|jpeg|png|gif)$/.test(url);
  }

  const handleNewMessageChange = (event) => {
    setNewMessage(event.target.value);
  };

  const handleInternalChange = (event) => {
    setIsInternal(event.target.checked);
  };

  const handleRequestComment = () => {
    setShowForm('comment');
    setNewMessage('');
  };

  const handleCopyId = () => {
    const textToCopy = `${fileDetails.id} - ${fileDetails.vehicleDescription}`;

    navigator.clipboard.writeText(textToCopy)
        .then(() => {
            setCopySuccess(true);
            setTimeout(() => {
                setCopySuccess(false);
            }, 1000);
        })
        .catch(err => {
            console.error('Could not copy text: ', err);
        });
};

  const markAsRead = async (fileId) => {
    setFileDetails(prevDetails => ({ ...prevDetails, message: 0 }));
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + '/markAsRead', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',

        },
        body: JSON.stringify({ fileId }),
      });

      if (!response.ok) {
        console.error('Failed to mark as read');
        setFileDetails(prevDetails => ({ ...prevDetails, message: 1 }));
        return;
      }
    } catch (error) {
      console.error('Error marking as read:', error);
      setFileDetails(prevDetails => ({ ...prevDetails, message: 1 }));
    }
  };

  useEffect(() => {
    if (fileDetails && (isAdmin || isTuner)) {
      const fetchRegnumData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/check-regnum/${fileDetails.regnum}`);
          const data = await response.json();

          if (data.found) {
            setRegnumMatches(data.ids);
          } else {
            setRegnumMatches([]);
          }
        } catch (error) {
          console.error('Error fetching regnum data:', error);
        }
      };

      fetchRegnumData();
    }
  }, [fileDetails, isAdmin, isTuner]);

  useEffect(() => {
    const fetchModfileMatches = async (dialogId) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/modfile-matches-dialog/${dialogId}`);

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setModfileMatchesByDialog(prevState => ({
          ...prevState,
          [dialogId]: data
        }));
      } catch (error) {
        console.error(`Error fetching modfile matches for dialog ${dialogId}:`, error);
        setModfileMatchesByDialog(prevState => ({
          ...prevState,
          [dialogId]: []
        }));
      }
    };

    if (dialogs && dialogs.length > 0) {
      dialogs.forEach(dialog => {
        fetchModfileMatches(dialog.id);
      });
    }
  }, [dialogs]);

  const handleUploadSubmit = async (event) => {
    event.preventDefault();
    const files = fileInputRef.current ? fileInputRef.current.files : [];

    if (files && files.length > 0) {
      const formData = new FormData();
      formData.append('file', files[0]);
      formData.append('fileid', fileId);
      formData.append('user', userProfile.name);
      formData.append('message', newMessage);
      try {
        const uploadResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/uploadDialogueFile`, {
          method: 'POST',
          body: formData,
        });
        if (!uploadResponse.ok) {
          throw new Error('File upload failed');
        }
        const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
        if (!dialogsResponse.ok) {
          throw new Error('Failed to fetch updated dialogs');
        }
        const updatedDialogs = await dialogsResponse.json();
        const processedDialogs = await Promise.all(updatedDialogs.map(async (dialog) => {
          const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
          const data = await response.json();
          let companyName = data.companyName;
          let companyStyle = { color: 'green' };
          if (data.admin === 1 || data.tuner === 1) {
            companyName = 'Optifiles';
            companyStyle = { color: 'red' };
          }
          if (data.support === 1) {
            companyName += '';
            companyStyle = { color: 'green' };
          }
          return { ...dialog, companyName, companyStyle, isSupport: data.support === 1 };
        }));
        setDialogs(processedDialogs);

      } catch (error) {
        console.error('Error uploading file or fetching dialogs:', error);
      }
    } else {
      const payload = {
        fileId: fileId,
        user: userProfile.name,
        message: newMessage,
        internal: isInternal ? 1 : 0,
        rredo: showForm === 'redo' ? 1 : 0,
        rcredit: showForm === 'creditback' ? 1 : 0,
        dealercheck: isAdmin ? 1 : fileDetails.dealercheck,
        tunercheck: isAdmin ? fileDetails.tunercheck : 1,
        messageStatus: isAdmin ? 2 : 1,
      };

      try {
        const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });

        if (!messageResponse.ok) {
          throw new Error('Failed to post message');
        }

        const messageData = await messageResponse.json();
        setDialogs(prevDialogs => [...prevDialogs, {
          ...payload,
          id: messageData.dialogId,
          date: new Date().toISOString(),
          companyName: userProfile.companyName,
          companyStyle: { color: isAdmin ? 'red' : 'green' },
          isSupport: userProfile.support === 1
        }]);

        const sendNotification = async (userId, title, body, data = {}) => {
          try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userProfile.token}`
              },
              body: JSON.stringify({ userId, title, body, data })
            });
            if (!response.ok) {
              throw new Error('Failed to send notification');
            }
            const result = await response.json();
          } catch (error) {
            console.error('Error sending notification:', error);
          }
        };

        const sendNotificationToAdmin = async (title, body, data = {}) => {
          try {
            const response = await fetch(`${process.env.REACT_APP_SITE_URL}/send-notification-to-admins`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userProfile.token}`
              },
              body: JSON.stringify({ title, body, data })
            });

            if (!response.ok) {
              throw new Error('Failed to send notification to admin');
            }
            const result = await response.json();
          } catch (error) {
            console.error('Error sending notification to admin:', error);
          }
        };

        if (isAdmin) {
        sendNotification(
          userProfile.id,
          'Optifiles',
          'ID ' + fileId + ': ' + newMessage,
          { messageId: '123', sender: 'John Doe' }
        )
        } else {
          sendNotificationToAdmin(
            userProfile.companyName + ' (' + userProfile.name + ')',
            'ID ' + fileId + ': ' + newMessage,
            { messageId: '123', sender: 'John Doe' }
          )
        }
      } catch (error) {
        console.error('Error posting message:', error);
      }
    }

    setNewMessage('');
    setIsInternal(false);
    setSelectedFiles([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    setShowForm(null);
  };

  const renderLinks = (urlsString) => {
    if (!urlsString) return null;

    const urls = urlsString.split(',');
    const imageElements = urls.filter(isImage).map((url, index) => (
      <img
        key={index}
        src={url}
        alt={`Uploaded File ${index + 1}`}
        className="thumbnail"
        onClick={() => window.open(url, '_blank')}
      />
    ));

    const downloadLinkElements = urls.filter(url => !isImage(url)).map((url, index) => (
      <a key={index} href={url} target="_blank" rel="noopener noreferrer" className="download-link">
        {url.split('/').pop()}
      </a>
    ));

    return (
      <>
        <div className="thumbnails-container">{imageElements}</div>
        <div className="download-links-container">{downloadLinkElements}</div>
      </>
    );
  };

  const handleWipChange = async () => {
    const newWipStatus = !fileDetails.wip;
    try {
      const response = await fetch(process.env.REACT_APP_SITE_URL + `/updateWipStatus`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userProfile.token}` },
        body: JSON.stringify({ fileId: fileDetails.id, wip: newWipStatus })
      });
      if (response.ok) {

        setFileDetails(prevDetails => ({ ...prevDetails, wip: newWipStatus }));
      } else {
        throw new Error('Failed to update WIP status');
      }
    } catch (error) {
      console.error('Error updating WIP status:', error);
    }
  };

  const fetchFileOptions = async (dialog) => {
    if (!dialog.oriurl && !dialog.gearurl) {
      return null;
    }
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/file-options/${dialog.id}`);
      if (!response.ok) {
        throw new Error('Failed to fetch file options');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching file options:', error);
      return null;
    }
  };

  const constructAvatarUrl = (relativePath) => {
    let baseUrl = process.env.REACT_APP_SITE_URL;
    if (baseUrl.includes('/api')) {
        baseUrl = baseUrl.split('/api')[0];
    }
    return `${baseUrl}${relativePath}`;
};

const MessageAvatar = ({ dialog }) => {
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
      if (dialog.avatar) {
          const constructedAvatarUrl = constructAvatarUrl(dialog.avatar);
          setAvatarUrl(constructedAvatarUrl);
      }
  }, [dialog.avatar]);

  return (
      <div className="message-avatar">
          {avatarUrl ? (
              <img src={avatarUrl} alt="Avatar" className="avatar-icon" />
          ) : (
              <>
                  {getMessageType(dialog).includes('dealer-message') && <span className="avatar-icon">💼</span>}
                  {getMessageType(dialog).includes('admin-message') && <span className="avatar-icon">👤</span>}
              </>
          )}
      </div>
  );
};

const handleDownload = (url, fileDetails) => {
  const originalFilename = url.substring(url.lastIndexOf('/') + 1);
  const sanitizeForFilename = (value) => {
    return String(value).replace(/[^a-z0-9]/gi, '_');
  };
  const sanitizedId = sanitizeForFilename(fileDetails.id);
  const sanitizedRegnum = sanitizeForFilename(fileDetails.regnum).toUpperCase();
  const newFilename = `${sanitizedId}_${sanitizedRegnum}_${originalFilename}`;

  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', newFilename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const handleUpdateStatus = async (id, url) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 1, tuner: userProfile.name }),
    });

    if (!response.ok) {
      console.error('Failed to update status');
      return;
    }

    setFileDetails(prevDetails => ({
      ...prevDetails,
      status: 1, // Oppdater status til 'IP'
    }));

    const payload = {
      fileId: fileDetails.id,
      user: 'Optifiles',
      message: 'File has been downloaded and status set to in progress.',
      internal: isInternal ? 1 : 0,
      rredo: 0,
      rcredit: 0,
      urls: '',
      dealercheck: isAdmin ? 0 : 0,
      tunercheck: isAdmin ? 0 : 1,
    };

    const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!messageResponse.ok) {
      const messageData = await messageResponse.json();
      console.error('Error posting message:', messageData.message);
      return;
    }

    const messageData = await messageResponse.json();
    setDialogs(prevDialogs => [...prevDialogs, { ...payload, id: messageData.id }]);
    handleDownload(url, fileDetails);

  } catch (error) {
    console.error('Error updating status or posting message:', error);
  }
};




const handleDialogLog = async (id, url) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-status/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ status: 1, tuner: userProfile.name }),
    });

    if (!response.ok) {
      console.error('Failed to update status');
      return;
    }

    const payload = {
      fileId: fileDetails.id,
      user: 'Optifiles',
      message: 'File has been downloaded and status set to in progress.',
      internal: isInternal ? 0 : 1,
      rredo: 0,
      rcredit: 0,
      urls: '',
      dealercheck: isAdmin ? 0 : 0,
      tunercheck: isAdmin ? 0 : 1,
    };

    const messageResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/postMessage`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!messageResponse.ok) {
      const messageData = await messageResponse.json();
      console.error('Error posting message:', messageData.message);
      return;
    }

    setDialogs(prevDialogs => [...prevDialogs, payload]);
    handleDownload(url, fileDetails);

  } catch (error) {
    console.error('Error updating status or posting message:', error);
  }
};

const getTuningTypeClass = (tuningTypeValue) => {
  switch (tuningTypeValue.toLowerCase()) {
    case 'eco':
      return 'tuning-type-eco';
    case 'opti':
      return 'tuning-type-opti';
    case 'power':
      return 'tuning-type-power';
    case 'as stock':
      return 'tuning-type-as-stock';
    case 'extreme':
      return 'tuning-type-extreme';
    default:
      return '';
  }
};

useEffect(() => {
  const fetchData = async () => {
    try {
      const detailsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/file/${fileId}`);
      const detailsData = await detailsResponse.json();
      setFileDetails(detailsData);

      const dialogsResponse = await fetch(`${process.env.REACT_APP_SITE_URL}/filecom/${fileId}`);
      const dialogsData = await dialogsResponse.json();

      const updatedDialogs = await Promise.all(dialogsData.map(async (dialog) => {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-did-company/${dialog.user}`);
        const data = await response.json();

        let companyName = data.companyName;
        let companyStyle = { color: 'green' };

        if (data.admin === 1 || data.tuner === 1) {
          companyName = 'Optifiles';
          companyStyle = { color: 'red' };
        }
        if (data.support === 1) {
          companyName += '';
          companyStyle = { color: 'green' };
        }
        const fileOptions = await fetchFileOptions(dialog);
        return { ...dialog, companyName, companyStyle, isSupport: data.support === 1, fileOptions };
      }));

      setDialogs(updatedDialogs);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchData();
}, [fileId]);

  const EditRegModal = ({ visible, onClose, value, onChange, onSave }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (visible) {
            inputRef.current.focus();
        }
    }, [visible]);

    if (!visible) return null;

    return (
        <div className="editprofilemodal-background">
            <div className="editprofilemodal-content" onClick={(e) => e.stopPropagation()}>
                <h3>Edit Licenseplate of ID: {fileDetails.id}</h3>
                <input
                    ref={inputRef}
                    type="text"
                    value={value}
                    onChange={onChange}
                />
                <button className="confirm-button" onClick={onSave}>Save</button>
                <button className="decline-button" onClick={onClose}>Cancel</button>
            </div>
        </div>
    );
};

const handleTextClick = () => {
  setEditRegValue(fileDetails.regnum);
  setIsEditRegModalVisible(true);
};

const handleInputChange = (e) => {
  setEditRegValue(e.target.value);
};
const formatDateTime = (dateTimeString) => {
  if (!dateTimeString || isNaN(new Date(dateTimeString).getTime())) {
    console.warn('Invalid date received:', dateTimeString);
    return 'Invalid Date';
  }
  const options = {
    timeZone: 'UTC',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  };
  return new Intl.DateTimeFormat('no-NO', options).format(new Date(dateTimeString));
};

const handleSave = async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateRegnum`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`
      },
      body: JSON.stringify({ fileId: fileDetails.id, regnum: editRegValue })
    });

    if (response.ok) {
      setFileDetails(prevDetails => ({ ...prevDetails, regnum: editRegValue }));
      setIsEditRegModalVisible(false);
    } else {
      throw new Error('Failed to update license plate');
    }
  } catch (error) {
    console.error('Error updating license plate:', error);
  }
};

  if (!fileDetails) return <div>No file details available.</div>;
  const buttonClass = fileDetails.message === 1 ? 'read-button' : 'read-button-done';
  const buttonText = fileDetails.message === 1 ? 'Merk som lest' : 'Lest';
  const dealerStatusClass = fileDetails.dealercheck === 1 ? 'status-open' : 'status-closed';
  const dealerStatusText = fileDetails.dealercheck === 1 ? 'OPEN' : 'CLOSED';
  const handleDeleteMessage = async (messageId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/deleteDialogueMessage/${messageId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${userProfile.token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to delete message');
        }
        setDialogs(prevDialogs => prevDialogs.filter(dialog => dialog.id !== messageId));
      } catch (error) {
        console.error('Error deleting message:', error);
        alert('Failed to delete message. Please try again.');
      }
    }
  };

  const isCustomType = [6, 8, 9].includes(fileDetails.v_type);



 const calculateEstimatedCredits = (fileOptions) => {
  let credits = companyLevel.baseCredits || 0;
  const type = fileDetails.v_type;
  const isCustomType = [6, 8, 9].includes(type);
  const tuningTypeCredit = isCustomType ? 12 : 4;
  if (fileOptions.tuning_type_value && fileOptions.tuning_type_value !== '5') {
    credits += tuningTypeCredit;
  }
  const optionCosts = {
    egr: isCustomType ? 15 : 3,
    dpf: isCustomType ? 15 : 3,
    adblue: isCustomType ? 20 : 5,
    nsl: isCustomType ? 10 : 2,
    o2: 2,
    epa: 7,
    hybridturbo: 7,
    gearbox: 5,
    coldstart: 2,
    startstop: 2,
    popsbangs: 5,
    burbles: 5,
    swirlflaps: 2,
    tva: 2,
    nonstock: 0,
  };

  Object.keys(optionCosts).forEach((option) => {
    if (fileOptions[option] == 1) {
        credits += optionCosts[option];
    }
});

Object.keys(selectedOptions).forEach(option => {
    if (selectedOptions[option] && selectedOptions[option] !== initialOptions.current[option]) {
        credits += optionCosts[option] || 0;
    }
});

  if (fileOptions.euro_class === 2) {
    if (isCustomType) {
      return 70;
    }
    if (Object.values(fileOptions).some(value => value !== 0)) {
      return 70;
    }
  }

  const finalCredits = credits > 50 ? 50 : credits;
  console.log(finalCredits);
  return finalCredits;
};

const tuningTypeMap = {
  '1': 'ECO - Fair Power, Maximum Economy',
  '3': 'OPTI - Good Power, Improved Fuel Economy',
  '4': 'POWER - High Power, Ultimate Performance',
  '5': 'AS STOCK - No performance Increase'
};

const updatePaymentStatus = async (fileId, paidStatus) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/update-paid-status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${userProfile.token}`,
      },
      body: JSON.stringify({ fileId, paidStatus }),
    });

    if (!response.ok) {
      throw new Error('Failed to update payment status');
    }

    console.log('Payment status updated successfully');
    setShowEditModal(false)
    setFileDetails(prevDetails => ({ ...prevDetails, paid: paidStatus }));
  } catch (error) {
    console.error('Failed to update payment status', error);
  }
};


const getFileNameFromURL = (url) => {
  if (!url) {
    return ''; // Returner en tom streng eller en annen standardverdi hvis url er null eller undefined
  }
  return url.substring(url.lastIndexOf('/') + 1);
};

const handleRequestCreditBack = () => {
  setShowCreditBackModal(true);
};

const handleCreditBackSubmit = async (logFile, comment) => {
  const formData = new FormData();
  formData.append('logFile', logFile);
  formData.append('comment', comment);
  formData.append('fileId', fileDetails.id);
  formData.append('user', userProfile.name);

  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/requestCreditBack`, {
      method: 'POST',
      body: formData,
      headers: {
        'Authorization': `Bearer ${userProfile.token}`
      }
    });

    if (!response.ok) {
      throw new Error('Failed to submit credit back request');
    }

    const result = await response.json();

    setDialogs(prevDialogs => [...prevDialogs, {
      id: result.filecomId,
      user: userProfile.name,
      message: `Credit back request: ${comment}`,
      date: new Date().toISOString(),
      rcredit: 1,
      companyName: userProfile.companyName,
      companyStyle: { color: 'green' },
      status: 'pending'
    }]);

    setShowCreditBackModal(false);
    alert('Credit back request submitted successfully');
  } catch (error) {
    console.error('Error submitting credit back request:', error);
    alert('Failed to submit credit back request. Please try again.');
  }
};

const handleCreditBackAction = (dialogId, action) => {
  const adminComment = prompt(`Please enter a comment for ${action}ing this credit back request:`);
  if (adminComment === null) return; // User cancelled the prompt

  if (adminComment.trim() === '') {
    alert('A comment is required.');
    return;
  }

  updateCreditBackRequest(dialogId, action, adminComment);
};

const updateCreditBackRequest = async (dialogId, action, adminComment) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_SITE_URL}/updateCreditBackRequest`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${userProfile.token}`
      },
      body: JSON.stringify({
        dialogId,
        action,
        adminComment,
        adminId: userProfile.name
      })
    });

    if (!response.ok) {
      throw new Error('Failed to update credit back request');
    }

    const result = await response.json();

    // Update the original dialog status
    setDialogs(prevDialogs => prevDialogs.map(dialog =>
      dialog.id === dialogId
        ? { ...dialog, status: action }
        : dialog
    ));

    // Add a new dialog for the admin response
    const newDialog = {
      id: Date.now(), // temporary id, you might want to fetch the actual id from the server
      user: userProfile.name,
      message: action === 'accepted'
        ? `Accepted: ${adminComment}\nCredits returned: ${result.creditedAmount}`
        : `Denied: ${adminComment}`,
      date: new Date().toISOString(),
      rcredit: 0,
      status: action,
      companyName: 'Optifiles',
      companyStyle: { color: 'red' }
    };

    setDialogs(prevDialogs => [...prevDialogs, newDialog]);

    if (action === 'accepted') {
      setFileDetails(prevDetails => ({
        ...prevDetails,
        charged: 0 // Set charged to 0 as all credits have been returned
      }));
    }

    alert(`Credit back request ${action} successfully`);
  } catch (error) {
    console.error('Error updating credit back request:', error);
    alert('Failed to update credit back request. Please try again.');
  }
};

return (
  <div className="filemodal-background">
    <div className="filemodal-content" onClick={e => e.stopPropagation()}>
      <div className="modal-header">
        <button className="close-btn" onClick={handleClose}>CLOSE</button>
      </div>
      <div className="content-wrapper">
        <div className="column message-column">
          <div className="messages">
          {Array.isArray(dialogs) && dialogs.map((dialog, index) => (
          (dialog.internal === 0 || isAdmin || isTuner) && (
            <div key={index} className={`message-wrapper ${getMessageType(dialog)}`}>
              <div className="dialog-message">
                <MessageAvatar dialog={dialog} />
                <div className="message-content">
                  <div className="message-header">
                    <span className="dialog-user"><b>{dialog.user}</b></span>
                    <span className="dialog-date">{formatDateTime(dialog.date)}</span>
                  </div>
                  <div className="company-name" style={dialog.companyStyle}>
                    {dialog.companyName}
                    {dialog.isSupport && <span style={{ color: 'blue' }}> (Support)</span>}
                  </div>
                  <div className="message-body">
                  <p className="dialog-message-content">{dialog.message}          {isAdmin && (
                    <button className="delete-message-button" onClick={() => handleDeleteMessage(dialog.id)}>
                      <img src="/images/red-trash-can-icon.svg" alt="Delete" className="delete-icon" />
                    </button>
        )}</p>
        {dialog.message.startsWith("File upload:") && (
  <>
    <div className="dialogdownload-buttons">
      {(isAdmin || isTuner) && (
        <>

          <button onClick={() => handleUpdateStatus(fileDetails.id, dialog.oriurl)} className="oridownload-button">Original</button>
          <button onClick={() => handleDialogLog(fileDetails.id, dialog.oriurl)} className="orihiddownload-button">Ori (Hidden)</button>
          {fileDetails.tooltype === 1 && (
            <button onClick={() => handleDownload(dialog.oriurl, fileDetails)} className="oriencdownload-button">Ori (Encrypted)</button>
          )}<div className="modurl">ECU FILE: {getFileNameFromURL(dialog.oriurl)}</div>
              {dialog.gearurl && (
                <>
                  <button onClick={() => handleDownload(dialog.gearurl, fileDetails)} className="oridownload-button">Original</button>
                  {fileDetails.tooltype === 1 && (
                    <button onClick={() => handleDownload(dialog.gearurl, fileDetails)} className="oriencdownload-button">Ori (Encrypted)</button>
                  )}<div className="modurl">ACM / TCU FILE: {getFileNameFromURL(dialog.gearurl)}</div>
                </>
              )}
                {(isAdmin || isTuner) && !dialog.modurl && (
                  <div className="dialogdownload-buttons">
                    <button onClick={() => { setIsUploadModPopupVisible(true); setSelectedFilecomId(dialog.id); }} className="updownload-button">Upload Mod</button>
                  </div>
                )}
                    </>
                  )}
              </div>

                {isUploadModPopupVisible && (
                  <div className="upload-mod-popup-background">
                    <div className="upload-mod-popup-content" onClick={e => e.stopPropagation()}>
                    <div className="modal-header">
              <button className="close-btn" onClick={closeUploadModPopup}>CLOSE</button>
            </div>
            <form onSubmit={(e) => handleUploadModSubmit(e, dialog.fileOptions, selectedOptions)}>
                          <div className="file-input-row">
                          <div className="file-input-column">
                            <label className="file-input-label">Modfile:</label>
                            <input className="unique-file-input" type="file" name="modFile" onChange={e => setModFile(e.target.files[0])} />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Master:</label>
                            <input className="unique-file-input" type="file" name="masterFile" onChange={e => setMasterFile(e.target.files[0])} />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Gearbox:</label>
                            <input className="unique-file-input" type="file" name="gearboxFile" onChange={e => setGearboxFile(e.target.files[0])} />
                          </div>
                          <div className="file-input-column">
                            <label className="file-input-label">Gearbox Master:</label>
                            <input className="unique-file-input" type="file" name="gearboxMasterFile" onChange={e => setGearboxMasterFile(e.target.files[0])} />
                          </div>
                        </div>
                        <div className="selected-options-summary">
                          {dialog.fileOptions && (
                            <>
                            <div className="file-options">
                              {dialog.fileOptions.tuning_type_value && (
                                <p className={`file-option tuning-type ${getTuningTypeClass(dialog.fileOptions.tuning_type_value)}`}>
                                  {dialog.fileOptions.tuning_type_value}
                                </p>
                              )}
                              {dialog.fileOptions.egr !== 0 && <p className="file-option egr">EGR</p>}
                              {dialog.fileOptions.dpf !== 0 && <p className="file-option dpf">DPF</p>}
                              {dialog.fileOptions.adblue !== 0 && <p className="file-option adblue">AdBlue</p>}
                              {dialog.fileOptions.nsl !== 0 && <p className="file-option nsl">NSL</p>}
                              {dialog.fileOptions.o2 !== 0 && <p className="file-option o2">O2</p>}
                              {dialog.fileOptions.epa !== 0 && <p className="file-option epa">EPA</p>}
                              {dialog.fileOptions.hybridturbo !== 0 && <p className="file-option hybrid-turbo">Hybrid Turbo</p>}
                              {dialog.fileOptions.gearbox !== 0 && <p className="file-option gearbox">Gearbox</p>}
                              {dialog.fileOptions.coldstart !== 0 && <p className="file-option cold-start">Cold Start</p>}
                              {dialog.fileOptions.startstop !== 0 && <p className="file-option start-stop">Start/Stop</p>}
                              {dialog.fileOptions.popsbangs !== 0 && <p className="file-option pops-bangs">Pops & Bangs</p>}
                              {dialog.fileOptions.burbles !== 0 && <p className="file-option burbles">Burbles</p>}
                              {dialog.fileOptions.swirlflaps !== 0 && <p className="file-option swirl-flaps">Swirl Flaps</p>}
                              {dialog.fileOptions.tva !== 0 && <p className="file-option tva">TVA</p>}
                              {dialog.fileOptions.priority_value && <p className="file-option priority">Prioritet: {dialog.fileOptions.priority_value}</p>}
                              </div>
                              {dialog.fileOptions.euro_class && (
                                <p className="euroclass">{dialog.fileOptions.euro_class === 1 ? 'Euro 4/5' : 'Euro 6'}</p>
                              )}
                              <p><strong>Credits to be withdrawn:</strong> {calculateEstimatedCredits(dialog.fileOptions, companyLevel.baseCredits)}</p>
                              <div
  onClick={() => handleAdvancedToggle(dialog)}
  className="advanced-toggle-button"
  role="button"
  style={{ cursor: 'pointer', padding: '10px', backgroundColor: '#007bff', color: 'white', borderRadius: '5px', textAlign: 'center' }}
>
  {isAdvancedVisible ? 'Hide Advanced' : 'Show Advanced'}
</div>
                                {isAdvancedVisible && (
                                  <div className="advanced-options">
  <h3>Advanced Options</h3>

  <label>
    Tuning Type:
    <select
      value={selectedOptions.tuning_type || ''}
      onChange={(e) => handleOptionChange('tuning_type', e.target.value)}
    >
      <option value="">Select Tuning Type</option>
      {Object.entries(tuningTypeMap).map(([key, value]) => (
        <option key={key} value={key}>
          {value}
        </option>
      ))}
    </select>
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.egr || false}
      onChange={(e) => handleOptionChange('egr', e.target.checked)}
    />
    EGR ({isCustomType ? 15 : 3} credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.dpf || false}
      onChange={(e) => handleOptionChange('dpf', e.target.checked)}
    />
    DPF ({isCustomType ? 15 : 3} credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.adblue || false}
      onChange={(e) => handleOptionChange('adblue', e.target.checked)}
    />
    AdBlue ({isCustomType ? 20 : 5} credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.nsl || false}
      onChange={(e) => handleOptionChange('nsl', e.target.checked)}
    />
    NSL ({isCustomType ? 10 : 2} credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.o2 || false}
      onChange={(e) => handleOptionChange('o2', e.target.checked)}
    />
    O2 (2 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.epa || false}
      onChange={(e) => handleOptionChange('epa', e.target.checked)}
    />
    EPA (7 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.hybridturbo || false}
      onChange={(e) => handleOptionChange('hybridturbo', e.target.checked)}
    />
    Hybrid Turbo (7 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.gearbox || false}
      onChange={(e) => handleOptionChange('gearbox', e.target.checked)}
    />
    Gearbox (5 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.coldstart || false}
      onChange={(e) => handleOptionChange('coldstart', e.target.checked)}
    />
    Cold Start (2 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.startstop || false}
      onChange={(e) => handleOptionChange('startstop', e.target.checked)}
    />
    Start/Stop (2 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.popsbangs || false}
      onChange={(e) => handleOptionChange('popsbangs', e.target.checked)}
    />
    Pops & Bangs (5 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.burbles || false}
      onChange={(e) => handleOptionChange('burbles', e.target.checked)}
    />
    Burbles (5 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.swirlflaps || false}
      onChange={(e) => handleOptionChange('swirlflaps', e.target.checked)}
    />
    Swirl Flaps (2 credits)
  </label>
  <label>
    <input
      type="checkbox"
      checked={selectedOptions.tva || false}
      onChange={(e) => handleOptionChange('tva', e.target.checked)}
    />
    TVA (2 credits)
  </label>
  {dialog.fileOptions.priority_value && (
    <label>
      <input
        type="checkbox"
        checked={selectedOptions.priority || false}
        onChange={(e) => handleOptionChange('priority', e.target.checked)}
      />
      Prioritet: {dialog.fileOptions.priority_value}
    </label>
  )}
  <p>Estimated Credits: {calculateEstimatedCredits(dialog.fileOptions)}</p>
</div>

                                )}
                              <div className="text-input-row">
                          <label className="text-input-label-uploadcomment">Dealerkommentar:</label>
                          <textarea className="unique-text-input" value={textInput1} onChange={e => setTextInput1(e.target.value)} />
                        </div>
                        <div className="text-input-row">
                          <label className="text-input-label-uploadcomment">Internkommentar:</label>
                          <textarea className="unique-text-input" value={textInput2} onChange={e => setTextInput2(e.target.value)} />
                        </div>
                        <input type="hidden" value={dialog.id} />
                        <button className="admin-button" type="submit">Upload</button>
                            </>
                          )}
                        </div>
                      </form>
                    </div>
                  </div>
                )}
          </>
        )}

          {dialog.fileOptions && (
            <div className="file-options">
              {dialog.fileOptions.tuning_type_value && (
                <p className={`file-option tuning-type ${getTuningTypeClass(dialog.fileOptions.tuning_type_value)}`}>
                  {dialog.fileOptions.tuning_type_value}
                </p>
                    )}
                    {dialog.fileOptions.egr !== 0 && <p className="file-option egr">EGR</p>}
                    {dialog.fileOptions.dpf !== 0 && <p className="file-option dpf">DPF</p>}
                    {dialog.fileOptions.adblue !== 0 && <p className="file-option adblue">AdBlue</p>}
                    {dialog.fileOptions.nsl !== 0 && <p className="file-option nsl">NSL</p>}
                    {dialog.fileOptions.o2 !== 0 && <p className="file-option o2">O2</p>}
                    {dialog.fileOptions.epa !== 0 && <p className="file-option epa">EPA</p>}
                    {dialog.fileOptions.hybridturbo !== 0 && <p className="file-option hybrid-turbo">Hybrid Turbo</p>}
                    {dialog.fileOptions.gearbox !== 0 && <p className="file-option gearbox">Gearbox</p>}
                    {dialog.fileOptions.coldstart !== 0 && <p className="file-option cold-start">Cold Start</p>}
                    {dialog.fileOptions.startstop !== 0 && <p className="file-option start-stop">Start/Stop</p>}
                    {dialog.fileOptions.popsbangs !== 0 && <p className="file-option pops-bangs">Pops & Bangs</p>}
                    {dialog.fileOptions.burbles !== 0 && <p className="file-option burbles">Burbles</p>}
                    {dialog.fileOptions.swirlflaps !== 0 && <p className="file-option swirl-flaps">Swirl Flaps</p>}
                    {dialog.fileOptions.tva !== 0 && <p className="file-option tva">TVA</p>}
                    {dialog.fileOptions.priority_value && <p className="file-option priority">{dialog.fileOptions.priority_value}</p>}
                  </div>
                )}
          {(isAdmin || isTuner) && modfileMatchesByDialog[dialog.id] && modfileMatchesByDialog[dialog.id].length > 0 && (
    <>
      <button className="toggle-matches-button" onClick={handleToggleMatches}>
        {showMatches ? 'Hide Matching Modfiles' : 'Show Matching Modfiles'}
      </button>
      {showMatches && (
        <div className="matches-container">
          <div className="matches-list">
            {modfileMatchesByDialog[dialog.id].map(id => (
              <div key={id} className="match-box" onClick={() => handleBoxClick(id)}>
                ID: {id}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  )}
              {dialog.uploadurl && renderLinks(dialog.uploadurl)}
              {dialog.url && renderLinks(dialog.url)}
              {dialog.rcredit === 1 && (
                <div className="credit-back-request">
                  {(isAdmin || isTuner) && dialog.status === 'pending' && (
                    <div className="admin-actions">
                      <button onClick={() => handleCreditBackAction(dialog.id, 'accepted')}>Accept</button>
                      <button onClick={() => handleCreditBackAction(dialog.id, 'deny')}>Deny</button>
                    </div>
                  )}
                  {dialog.adminComment && (
                    <p className="admin-comment">Admin comment: {dialog.adminComment}</p>
                  )}
                </div>
              )}
              {dialog.message.startsWith("File upload:") && (
              <>
                {dialog.modmessage && (
                  <div className="dialogdownload-buttons">
                    {dialog.modurl && (
                      <button onClick={() => handleDownload(dialog.modurl, fileDetails)} className="finishdownload-button">ECU MOD</button>
                    )}
                    {dialog.modgearurl && (
                      <button onClick={() => handleDownload(dialog.modgearurl, fileDetails)} className="finishdownload-button">ACM / TCU MOD</button>
                    )}
                    {(isAdmin || isTuner) && (
                      <>
                        {dialog.modmasterurl && (
                          <button onClick={() => handleDownload(dialog.modmasterurl, fileDetails)} className="finishdownloadmaster-button">ECU MASTER</button>
                        )}
                        {dialog.modgearmasterurl && (
                          <button onClick={() => handleDownload(dialog.modgearmasterurl, fileDetails)} className="finishdownloadmaster-button">ACM / TCU MASTER</button>
                        )}
                      </>
                    )}
                  </div>
                )}
<>
  <div className="modurl">{getFileNameFromURL(dialog.modurl)}</div>
  <div className="modurl">{getFileNameFromURL(dialog.gearurl)}</div>
  {(isAdmin || isTuner) && (
    <>
      <div className="modurl">MasterURLMod(Denne skal fjernes når fil blir renamet riktig): {getFileNameFromURL(dialog.modmasterurl)}</div>
      <div className="modurl">MasterGEARURLMod(Denne skal fjernes når fil blir renamet riktig): {getFileNameFromURL(dialog.modgearmasterurl)}</div>
    </>
  )}
</>
<div className="modmessage">{dialog.modmessage}</div>

              </>
            )}
          <div className="row-icons">
            {dialog.rredo === 1 && <span className="material-symbols-outlined">change_circle</span>}
            {dialog.uploaded === 1 && <div className="row-uploadedfile">UPLOADED FILE</div>}
          </div>
          {dialog.rredo === 1 && isAdmin && (
            <div className="row-answer">
              <div className="answer-button">Last opp fil, HER SVARER MAN</div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)
))}
            <div ref={messagesEndRef} />
          </div>
          <div className="selected-files">
            {selectedFiles.map((file, index) => (
              <div key={index} className="selected-file">
                {file.name}
                <button type="button" className="remove-file-button" onClick={() => handleRemoveFile(index)}>
                  &times;
                </button>
              </div>
            ))}
          </div>
          <form onSubmit={handleUploadSubmit} className="message-form">
            <div className="dropdown-container">
              <button type="button" onClick={toggleDropdown} className="upload-button">+</button>
              {dropdownVisible && (
                <div className="dropdown-menu">
                  <button type="button" onClick={() => fileInputRef.current.click()}>
                    <img src={filecomupload} alt="Alert" className="filecompng" />Upload a File
                  </button>
                  <button type="button" onClick={handleRequestComment}>
                    <img src={filecomredo} alt="Alert" className="filecompng" />Request redo
                  </button>
                  <button type="button" onClick={handleRequestCreditBack}>
                    <img src={filecomcredit} alt="Alert" className="filecompng" />Request creditback
                  </button>
                </div>
              )}
            </div>
            <input type="file" ref={fileInputRef} onChange={handleFileChange} multiple style={{ display: 'none' }} />
            <textarea
              ref={textareaRef}
              value={newMessage}
              onChange={e => {
                handleNewMessageChange(e);
                handleTextareaInput();
              }}
              placeholder="Message..."
              style={{ resize: 'none', overflow: 'hidden' }}
            />
            <button type="submit" className="send-message-button">Add Message</button>
            {isAdmin && (
              <label>
                <input
                  type="checkbox"
                  checked={isInternal}
                  onChange={handleInternalChange}
                /> Internal
              </label>
            )}
          </form>
        </div>
        <div className="column file-details-column">
          <div className="info-box">
            <p className="detailtextmedium"><b> {fileDetails.vehicleDescription}</b></p>
            <p className="detailtext"><b>ID:</b> {fileDetails.id}</p>
            {(isAdmin || isTuner) && (
              <>

                <button className="copy-button" onClick={handleCopyId}>Copy</button>
                {copySuccess && <div className="copy-notification">Vehicle copied!</div>}
                <button className={buttonClass} onClick={() => markAsRead(fileDetails.id)}>
                  {buttonText}
                </button>
                <div className="wip-toggle">
                <button
                  className={`sent-button ${fileDetails.status === 0 ? 'active-status-button' : ''}`}
                  onClick={() => changeStatusDirectly(fileDetails.id, 0)}
                >
                  Sent
                </button>
                <button
                  className={`ip-button ${fileDetails.status === 1 ? 'active-status-button' : ''}`}
                  onClick={() => changeStatusDirectly(fileDetails.id, 1)}
                >
                  In P
                </button>
                <button
                  className={`hold-button ${fileDetails.status === 3 ? 'active-status-button' : ''}`}
                  onClick={() => changeStatusDirectly(fileDetails.id, 3)}
                >
                  Hold
                </button>
                <button
                  className={`donedeal-button ${fileDetails.status === 2 ? 'done-status-button' : ''}`}
                  onClick={() => changeStatusDirectly(fileDetails.id, 2)}
                >
                  Done
                </button>

                  <br></br>
                  <p className="detailtext-inline">
                    <b>Dealerstatus: </b>
                    <span className={dealerStatusClass}>{dealerStatusText}</span>
                  </p>
                </div>
              </>
            )}
      <p className="detailtext">
      <p className="detailtext" onClick={handleTextClick}>
          <b>License plate:</b> {fileDetails.regnum}
          {isAdmin && (
          <>
                <button className="copy-button2" onClick={handleCopyToClipboard}>Copy</button>
            {copySuccess2 && <div className="copy-notification2">Licenseplate copied!</div>}
          </>
        )}
        </p>

      </p>
          <EditRegModal visible={isEditRegModalVisible} onClose={() => setIsEditRegModalVisible(false)} value={editRegValue} onChange={handleInputChange} onSave={handleSave} />
            <p className="detailtext"><b>Transmission:</b> {transmissionOptions[fileDetails.ttype]}</p>
            <p className="detailtext"><b>Fuel:</b> {fuelOptions[fileDetails.fuel_type]}</p>
            {dealerCountry === 'NO' ? (
vehicleData && vehicleData.kjoretoydataListe && vehicleData.kjoretoydataListe[0] ? (
  <div className="svv-box">
    <p className="detailtext"><b>Brand:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.generelt?.handelsbetegnelse?.[0] || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Licenseplate:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.kjennemerke || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>VIN:</b>  {vehicleData.kjoretoydataListe[0].kjoretoyId?.understellsnummer || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Registration date:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.forstegangsGodkjenning?.forstegangRegistrertDato || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Emission:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.euroKlasse?.kodeBeskrivelse?.substring(0, 8) || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Particulatefilter:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.forbrukOgUtslipp?.[0]?.partikkelfilterFabrikkmontert ? 'Ja' : 'Nei'}</p>
    <p className="detailtext"><b>Fueltype:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.miljodata?.miljoOgdrivstoffGruppe?.[0]?.drivstoffKodeMiljodata?.kodeBeskrivelse || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Enginesize:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.slagvolum ? `${vehicleData.kjoretoydataListe[0].godkjenning.tekniskGodkjenning.tekniskeData.motorOgDrivverk.motor[0].slagvolum} cm3` : "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Gearbox:</b>  {vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.girkassetype?.kodeBeskrivelse || "Ikke oppgitt"}</p>
    <p className="detailtext"><b>Power: </b>
      {(() => {
        const kW = vehicleData.kjoretoydataListe[0].godkjenning?.tekniskGodkjenning?.tekniskeData?.motorOgDrivverk?.motor?.[0]?.drivstoff?.[0]?.maksNettoEffekt;
        if (kW) {
          const hp = (kW * 1.36).toFixed(0);
          return `${kW} kW / ${hp} HP`;
        } else {
          return "Ikke oppgitt";
        }
      })()}
    </p>
  </div>
      ) : (
        <p>Loading vehicle data...</p>
      )
      ) : dealerCountry === 'SE' ? (
      <div className="svv-box">
        <p>Kan inte henta dator for Sverige</p>
      </div>
      ) : (
      <div className="svv-box">
        <p>Data is not available for this country</p>
      </div>
      )}
                </div>
          {fileDetails && (isAdmin || isTuner) && (
        <div className="info-box">
          <p className="detailtext"><b>{fileDetails.regnum} matches id:</b></p>
          {regnumMatches.length > 0 ? (
            <div className="id-boxes-container">
              {regnumMatches.map(id => (
                <div key={id} className="id-box" onClick={() => handleBoxClick(id)}>
                  ID: {id}
                </div>
              ))}
            </div>
          ) : (
            <p></p>
          )}<br></br>
        </div>
      )}
      {showModal && (
        <FileDetails
          fileId={selectedFileId}
          onClose={closeModal}
          isAdmin={isAdmin}
          isTuner={isTuner}
          userProfile={userProfile}
        />
      )}
          <div className="info-box">
            <p className="detailtextmedium"><b>{fileDetails.dealerName}<br /></b> {dealerCredit} credits left<br /></p>
            <p className="detailtext"><b>Tuningtool:</b> {tuningToolOptions[fileDetails.tooltype]}</p>
            <p className="detailtext"><b>Uploaded:</b> {new Date(fileDetails.created).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.created).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
            <p className="detailtext"><b>Modified:</b> {new Date(fileDetails.updated).toLocaleDateString('no-NO', { timeZone: 'UTC' })} {new Date(fileDetails.updated).toLocaleTimeString('no-NO', { timeZone: 'UTC' })}</p>
            <p className="detailtext"><b>Charged:</b> {fileDetails.charged}</p>
          </div>
          <div className="info-box">{fileDetails.country}{fileDetails.credit}
            <p className="detailtext"><b>Customer info:</b></p>
            <p className="detailtext">{fileDetails.customername}</p>
            <p className="detailtext">{fileDetails.customeraddress}</p>
            <p className="detailtext">{fileDetails.customerphone}</p>
            <p className="detailtext">{fileDetails.customeremail}</p>
            <p className="detailtext">{fileDetails.customernotes}</p>
            <p className="detailtext">
            <b>Paid:</b>
            <span className={`status-box ${fileDetails.paid === 1 ? 'paid' : 'not-paid'}`}>
              {fileDetails.paid === 1 ? '✔' : '✖'}
            </span>
            {!(isAdmin || isTuner) && (
            <button className="edit-status-button" onClick={() => setShowEditModal(true)}>Edit</button>
            )}
          </p>
          {showEditModal && (
            <div className="modal-background" onClick={() => setShowEditModal(false)}>
              <div className="uploadconfirmation" onClick={e => e.stopPropagation()}>
              <div className="modal-header">
              <button className="close-btn" onClick={() => setShowEditModal(false)}>CLOSE</button>
                      </div>
                <h3>Edit Payment Status</h3>
                <button
                    className="confirm-button"
                    onClick={() => updatePaymentStatus(fileDetails.id, 1)}
                  >
                    Paid
                  </button>
                  <button
                    className="decline-button"
                    onClick={() => updatePaymentStatus(fileDetails.id, 0)}
                  >
                    Not Paid
                  </button>
              </div>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
    <CreditBackModal
        isOpen={showCreditBackModal}
        onClose={() => setShowCreditBackModal(false)}
        fileDetails={fileDetails}
        onSubmit={handleCreditBackSubmit}
      />
  </div>
);
}

const CreditBackModal = ({ isOpen, onClose, fileDetails, onSubmit }) => {
  const [logFile, setLogFile] = useState(null);
  const [comment, setComment] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!logFile || !comment.trim()) {
      alert('Please upload a log file and add a comment.');
      return;
    }
    onSubmit(logFile, comment);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-background">
      <div className="creditback-modal">
        <h2>Request Credit Back</h2>
        <p>Credits withdrawn for this upload: {fileDetails.charged}</p>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Upload Log File:</label>
            <input
              type="file"
              onChange={(e) => setLogFile(e.target.files[0])}
              required
            />
          </div>
          <div>
            <label>Comment:</label>
            <textarea
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              required
            />
          </div>
          <button type="submit">Submit Request</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default FileDetails;
import React, { useState, useEffect } from 'react';

export default function Customers() {
    const [tasks, setTasks] = useState([]);
    const [filter, setFilter] = useState('All');
    const [sortedTasks, setSortedTasks] = useState([]);
    const [taskCount, setTaskCount] = useState(0);

    useEffect(() => {
        fetchTasks();
    }, []);

    useEffect(() => {
        filterTasks(filter);
    }, [tasks, filter]);

    const fetchTasks = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_SITE_URL + '/admin/tasks-not-done');
            const data = await response.json();
            setTasks(data.filter(task => task.done === 0));
        } catch (error) {
            console.error('Error fetching tasks:', error);
        }
    };

    const filterTasks = (filter) => {
        const now = new Date();
        let filtered = tasks;

        switch (filter) {
            case 'Today':
                filtered = tasks.filter(task => new Date(task.datetime).toDateString() === now.toDateString());
                break;
            case 'Week':
                const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay() + 1)); // Adjusted start of week to Monday
                const endOfWeek = new Date(startOfWeek);
                endOfWeek.setDate(startOfWeek.getDate() + 6);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfWeek && new Date(task.datetime) <= endOfWeek);
                break;
            case 'Month':
                const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
                const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfMonth && new Date(task.datetime) <= endOfMonth);
                break;
            case 'Year':
                const startOfYear = new Date(now.getFullYear(), 0, 1);
                const endOfYear = new Date(now.getFullYear(), 11, 31);
                filtered = tasks.filter(task => new Date(task.datetime) >= startOfYear && new Date(task.datetime) <= endOfYear);
                break;
            case 'Monday':
            case 'Tuesday':
            case 'Wednesday':
            case 'Thursday':
            case 'Friday':
            case 'Saturday':
            case 'Sunday':
                const dayIndex = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].indexOf(filter);
                filtered = tasks.filter(task => new Date(task.datetime).getDay() === dayIndex);
                break;
            case 'All':
            default:
                filtered = tasks;
                break;
        }

        setSortedTasks(filtered);
        setTaskCount(filtered.length);
    };

    const formatDateTime = (dateTimeString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false };
        return new Date(dateTimeString).toLocaleString('no-NO', { timeZone: 'UTC' }, options).replace(',', ' -');
    };

    const renderDayFilters = () => {
        const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

        return daysOfWeek.map(day => (
            <li key={day} onClick={() => setFilter(day)}>{day}</li>
        ));
    };

    const getFilterLabel = () => {
        switch (filter) {
            case 'Today':
                return 'Today';
            case 'Week':
                return 'This Week';
            case 'Month':
                return 'This Month';
            case 'Year':
                return 'This Year';
            case 'Monday':
            case 'Tuesday':
            case 'Wednesday':
            case 'Thursday':
            case 'Friday':
            case 'Saturday':
            case 'Sunday':
                return `This ${filter}`;
            case 'All':
            default:
                return 'All';
        }
    };

    return (
        <div className="admintasks-section">
            <div className="admintasks-sidebar">
                <h3>Filter Tasks</h3>
                <ul>
                    <li onClick={() => setFilter('Today')}>Today</li>
                    <li onClick={() => setFilter('Week')}>Week</li>
                    <li onClick={() => setFilter('Month')}>Month</li>
                    <li onClick={() => setFilter('Year')}>Year</li>
                    <li onClick={() => setFilter('All')}>All</li>
                </ul>
                <h4>Days of the Week</h4>
                <ul>
                    {renderDayFilters()}
                </ul>
            </div>
            <div className="admintasks-content">
                <h2>Tasks ({taskCount}) - {getFilterLabel()}</h2>
                <table className="admintasks-table">
                    <thead>
                        <tr>
                            <th>Vehicle</th>
                            <th>Info</th>
                            <th>Time</th>
                            <th>Customer</th>
                            <th>Dealer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortedTasks.map(task => (
                            <tr key={task.id}>
                                <td>{task.vehicle}</td>
                                <td>{task.info}</td>
                                <td>{formatDateTime(task.datetime)}</td>
                                <td>{task.customer}</td>
                                <td>{task.dealer_name}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

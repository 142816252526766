import React, { useState, useEffect, useContext } from 'react';
import { useAuth } from '../auth/AuthProvider';
import { UserContext } from '../context/UserContext';

export default function Support() {
  const { authToken } = useAuth();
  const { userProfile, setUserProfile } = useContext(UserContext);

  const [categories, setCategories] = useState({
    1: {
      id: 1,
      name: 'Introduce Yourself',
      icon: '👋',
      description: 'Few words about you',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    2: {
      id: 2,
      name: 'General Support / Help',
      icon: '🆘',
      description: 'The General Help & Discussions',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    3: {
      id: 3,
      name: 'Guides, Procedures & Tutorials',
      icon: '📚',
      description: 'The Installation Guides, Your Tip`s and Tutorials',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    },
    4: {
      id: 4,
      name: 'Hardware Discussions',
      icon: '💻',
      description: 'General Posts About Tools, Laptops, Diagnostic`s etc..',
      topicsCount: 0,
      messagesCount: 0,
      lastUser: '',
      lastMessageDate: ''
    }
  });

  const [topics, setTopics] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [currentTopic, setCurrentTopic] = useState(null);
  const [currentTopicTitle, setCurrentTopicTitle] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [newTitle, setNewTitle] = useState('');
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [userPostCounts, setUserPostCounts] = useState({});

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-profile`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          setUserProfile(data);
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };
    if (authToken) {
      fetchUserProfile();
    }
  }, [authToken, setUserProfile]);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/categories`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        const updatedCategories = { ...categories };
        data.forEach(category => {
          if (updatedCategories[category.categoryId]) {
            updatedCategories[category.categoryId].topicsCount = category.topicsCount;
            updatedCategories[category.categoryId].messagesCount = category.repliesCount;
          }
        });
        setCategories(updatedCategories);
      } else {
        throw new Error('Failed to fetch categories');
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [authToken]);

  const fetchTopics = async (categoryId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/category-topics/${categoryId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setTopics(data);
        setCurrentCategory(categoryId);
        setMessages([]);
        setCurrentTopic(null);
        setCurrentTopicTitle('');
      } else {
        throw new Error('Failed to fetch topics');
      }
    } catch (error) {
      console.error('Error fetching topics:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMessages = async (topicId, topicTitle) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/topic-messages/${topicId}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const data = await response.json();
        setMessages(data);
        setCurrentTopic(topicId);
        setCurrentTopicTitle(topicTitle);

        const uniqueUserIds = [...new Set(data.map(msg => msg.userid))];
        fetchUserPostCounts(uniqueUserIds);
      } else {
        throw new Error('Failed to fetch messages');
      }
    } catch (error) {
      console.error('Error fetching messages:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserPostCounts = async (userIds) => {
    try {
      const counts = {};
      await Promise.all(userIds.map(async (userId) => {
        const response = await fetch(`${process.env.REACT_APP_SITE_URL}/user-post-count/${userId}`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          counts[userId] = data.postCount;
        } else {
          counts[userId] = 0;
        }
      }));
      setUserPostCounts(counts);
    } catch (error) {
      console.error('Error fetching user post counts:', error);
    }
  };

  const handleBackToCategories = () => {
    window.location.reload();
  };

  const handleBackToTopics = () => {
    setCurrentTopic(null);
    setMessages([]);
  };

  const handlePostReply = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-reply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: newMessage,
          categoryId: currentCategory,
          topicId: currentTopic,
          userId: userProfile.id,
        }),
      });
      if (response.ok) {
        setNewMessage('');
        fetchMessages(currentTopic, currentTopicTitle);
      } else {
        throw new Error('Failed to create reply');
      }
    } catch (error) {
      console.error('Error creating reply:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePostTopic = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/create-topic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          title: newTitle,
          message: newMessage,
          categoryId: currentCategory,
          userId: userProfile.id,
        }),
      });
      if (response.ok) {
        setIsModalOpen(false);
        setNewTitle('');
        setNewMessage('');
        fetchTopics(currentCategory); // Refresh the topics list
      } else {
        throw new Error('Failed to create topic');
      }
    } catch (error) {
      console.error('Error creating topic:', error);
    } finally {
      setLoading(false);
    }
  };


  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('no-NO', { timeZone: 'UTC' }, options);
  };

  const adjustTextareaHeight = (textarea) => {
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
  };

  const handleThankMessage = async (messageId, thankedUserId) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_SITE_URL}/thank-message`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          userId: userProfile.id,
          messageId: messageId,
          thankedUserId: thankedUserId, // Legger til thankedUserId
        }),
      });
      if (response.ok) {
        fetchMessages(currentTopic, currentTopicTitle); // Oppdaterer meldinger
      } else {
        throw new Error('Failed to update thank');
      }
    } catch (error) {
      console.error('Error updating thank:', error);
    } finally {
      setLoading(false);
    }
  };



  const renderCategories = () => (
    <div className="categories-list">
      {Object.keys(categories).map((categoryId) => (
        <div key={categoryId} className="category" onClick={() => fetchTopics(categoryId)}>
          <div className="category-column">
            <h3>{categories[categoryId].icon} {categories[categoryId].name}</h3>
            <p>{categories[categoryId].description}</p>
          </div>
          <div className="category-column">
            <span>Topics: {categories[categoryId].topicsCount}</span>
            <span>Replies: {categories[categoryId].messagesCount}</span>
          </div>
          <div className="category-column">
            <span>Last post by {categories[categoryId].lastUser}</span>
            <span>{formatDate(categories[categoryId].lastMessageDate)}</span>
          </div>
        </div>
      ))}
    </div>
  );

  const renderTopics = () => (
    <div className="topics-list">
      <button className="create-topic-button" onClick={() => setIsModalOpen(true)}>New Topic</button>
      <h2>
        <span className="clickable" onClick={handleBackToCategories}>Forum</span> - {categories[currentCategory]?.name}
      </h2>
      {topics.length === 0 ? <div>No topics found</div> : null}
      {topics.map((topic) => (
        <div key={topic.topicid || topic.id} className="topic" onClick={() => fetchMessages(topic.topicid || topic.id, topic.title)}>
          <div className="topic-column">
            <h3>{topic.title}</h3>
            <p>Author: {topic.userName}</p>
          </div>
          <div className="topic-column">
            <span>Replies: {topic.reply_count || 0}</span>
            <span>Views: {topic.view_count || 0}</span>
          </div>
          <div className="topic-column">
            <span>Last post by {topic.last_user || 'Unknown'}</span>
            <span>{formatDate(topic.last_message_date)}</span>
          </div>
        </div>
      ))}
    </div>
  );

  const renderMessages = () => (
    <div className="support-messages-list">
      <h2>
        <span className="clickable" onClick={handleBackToCategories}>Forum</span> -
        <span className="clickable" onClick={handleBackToTopics}>{categories[currentCategory]?.name}</span> - {currentTopicTitle || 'Unknown Topic'}
      </h2>
      {messages.length === 0 ? <div>No messages found</div> : null}
      {messages.map((message) => (
        <div key={message.id} className="support-message">
          <div className="support-message-header">
            <div className="support-user-info">
              <div className="support-user-column">
                <span className="support-username">{message.userName}</span>
                <span>{message.companyName || 'Unknown Company'}</span>
              </div>
              <div className="support-user-column">
                <span>Replies: {message.user_posts || 0}</span>
                <span>Topics: {message.user_topics || 0}</span>
              </div>
              <div className="support-user-column">
                <span>Thanks Given: {message.thanks_given || 0}</span>
                <span>Thanks Received: {message.thanks_received || 0}</span>
              </div>
            </div>
            <div className="support-message-date">
              {formatDate(message.message_date)}
            </div>
          </div>
          <div className="support-message-content">
            {message.message}
          </div>
          <button className="thank-button" onClick={() => handleThankMessage(message.id, message.userid)}>Thank</button>
        </div>
      ))}
      <form onSubmit={handlePostReply}>
        <textarea
          placeholder="Skriv en ny melding..."
          value={newMessage}
          onChange={(e) => {
            setNewMessage(e.target.value);
            adjustTextareaHeight(e.target);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handlePostReply(e);
            }
          }}
        />
        <button type="submit">Post Reply</button>
      </form>
    </div>
  );


  return (
    <div className="support-container">
      {loading && <div className="loader">Loading...</div>}
      <div className="header">
        <div className="support-info">
          Welcome {userProfile?.name} to the support forums! Here you can ask questions, get help and discuss with other users.
        </div>
      </div>
      <div className="content">
        {currentTopic ? renderMessages() : (currentCategory ? renderTopics() : renderCategories())}
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="support-modal-create-background">
            <div className="support-modal-create">
              <span className="close" onClick={() => setIsModalOpen(false)}>&times;</span>
              <h2>Opprett ny topic</h2>
              <form onSubmit={handlePostTopic}>
                <input
                  type="text"
                  placeholder="Title"
                  value={newTitle}
                  onChange={(e) => setNewTitle(e.target.value)}
                />
                <textarea
                  placeholder="Message"
                  value={newMessage}
                  onChange={(e) => {
                    setNewMessage(e.target.value);
                    adjustTextareaHeight(e.target);
                  }}
                />
                <button className="create-topic-button" type="submit">Create topic</button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React from 'react';

export const SvgAT = ({ color = '#FFA500' }) => (
  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" fill={color} />
    <text x="50%" y="55%" fontFamily="sans-serif" fontSize="20" fill="white" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">AT</text>
  </svg>
);

export const SvgD = ({ color = '#ff000000' }) => (
  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" fill={color} />
    <text x="50%" y="55%" fontFamily="sans-serif" fontSize="25" fill="red" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">D</text>
  </svg>
);

export const SvgT = ({ color = '#ff000000' }) => (
  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" fill={color} />
    <text x="50%" y="55%" fontFamily="sans-serif" fontSize="20" fill="blue" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">T</text>
  </svg>
);

export const SvgM = ({ color = '#FFFFFF' }) => (
  <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
    <rect width="25" height="25" fill={color} />
    <text x="50%" y="55%" fontFamily="sans-serif" fontSize="18" fill="red" dominantBaseline="middle" textAnchor="middle" fontWeight="bold" fontStyle="italic">M</text>
  </svg>
);